import {BounceLoader} from 'react-spinners';
import { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowLeft } from "react-icons/md";
import ConsoleHelper from '../../utils/consoleHelper';
import { IoIosNotificationsOutline } from "react-icons/io";
import { useToast } from '../../contextProviders/ToastContext';
import ScreeningService from '../../services/screening.service';
import LoadingComponent from '../../components/Common/LoadingComponent';
import NotificationComponent from "./../../components/HR/Dashboard/NotificationComponent";

export default function AdminConfig(){

    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const [botVoices, setBotVoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingVoiceAudio, setLoadingVoiceAudio] = useState(false);
    const [selectedBotVoice, setBotVoice] = useState('en-US-AriaNeural');
    const { showErrorMessage, showSuccessMessage} = useToast();
    const [notificationsVisibility, setNotificationVisibility] = useState(false);

    const [avatars, setAvatars] = useState([
        {
            id: 1, 
            active: true,
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar1.svg', 
        },
        {
            id: 2, 
            active: true,
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar2.jpg', 
        },
        {
            id: 3, 
            active: false,
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar3.jpg', 
        },
        {
            id: 4, 
            active: false,
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar4.jpg', 
        },
        {
            id: 5, 
            active: false,
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar5.jpg', 
        },
        {
            id: 6, 
            active: false,
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar6.jpg', 
        },
        {
            id: 7, 
            active: false,
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar7.jpg', 
        },
        {
            id: 8, 
            active: false,
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar8.jpg', 
        },
        {
            id: 9, 
            active: false,
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar9.jpg', 
        },
        {
            id: 10, 
            active: false,
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar10.jpg', 
        },
        {
            id: 11, 
            active: false,
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar11.jpg', 
        },
    ])

    const toggleNotificationVisibility = () => {
        setNotificationVisibility(!notificationsVisibility);
    }

    // const hasChanged = () => {
    //     if(previousConfig?.selectedBotVoice !== selectedBotVoice)
    //         return true;
    //     else if(previousConfig?.recordingResolution !== recordingResolution)
    //         return true;
    //     return false;
    // }

    const playVoice = async () => {
        try {
            setLoadingVoiceAudio(true);
            const requestData = {
                text: 'Hello, This is Athmiya'
            };
            if(typeof selectedBotVoice === Object)
                requestData['voice'] = selectedBotVoice['value'];
            else requestData['voice'] = selectedBotVoice;

            const response = await ScreeningService.getVoiceAudio(requestData);
            setLoadingVoiceAudio(false);
            if(response.status === 200){
                const url = createAudioUrl(response.data['audio_stream']);
                const audio = new Audio(url);;
                audio.play();  
            }
        } catch (error) {
            setLoadingVoiceAudio(false);
            ConsoleHelper.log(`playVoice ${JSON.stringify(error)}`)
        }
    };

    const createAudioUrl = (audioStream) => {
        const binaryString = atob(audioStream);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++)
            bytes[i] = binaryString.charCodeAt(i);
        const blob = new Blob([bytes], { type: 'audio/mpeg' });
        return URL.createObjectURL(blob);
    };

    // useEffect(() => {
    //     async function getConfig(){
    //         try {
    //             const response = await AuthService.getConfig();
    //             if(response.status === 200){
    //                 setPreviousConfig(response.data?.data);
    //                 setBotVoice(response.data?.data?.selectedBotVoice || 24);
    //                 setRecordingResolution(response.data?.data?.recordingResolution || 720);
    //             }
    //         } catch (error) {
    //             ConsoleHelper.error(JSON.stringify(error));
    //         }
    //     }
    //     getConfig();
    // }, []);

    useEffect(() => {
        async function getVoices(){
            try {
                setLoading(true);
                const response = await ScreeningService.getVoices();
                setLoading(false);
                if(response.status === 200){
                    const data = response.data;
                    setBotVoices(data?.map((item) => ({label: item['name'], value: item['name'], language: item['language']})));
                }
            } catch (error) {
                setLoading(false);
                ConsoleHelper.error(JSON.stringify(error));
            }
        }

        getVoices();
    }, []);

    const updateConfig = async () => {
        try {
            setLoading(true);
            // const response = await AuthService.updateConfig({
            //     selectedBotVoice,
            //     // recordingResolution
            // });
            setLoading(false)
            // if(response.status === 200){
            //     setPreviousConfig({
            //         selectedBotVoice,
            //         // recordingResolution
            //     });
            //     showSuccessMessage({summary: 'Updated', detail: "Updated config"})
            // }
        } catch (error) {
            setLoading(false);
            showErrorMessage({summary: 'Failed', detail: "Failed to update config"})
        }
    }

    return (
        <div className="flex flex-col gap-5 p-5">
            <div className={`${loading? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-10 rounded-md w-1/4 flex justify-center items-center">
                    <LoadingComponent/>
                </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-1 text-[#161616] cursor-pointer">
                    <MdKeyboardArrowLeft onClick={goBack} size={26}/>
                    <h1 className='text-xl text-primary font-bold'>Config</h1>
                </div>
                <div className='flex gap-6 text-brownGray'>
                    <div className="flex justify-center">
                        <div className="bg-white h-10 w-10 rounded-full flex justify-center items-center cursor-pointer">
                            <IoIosNotificationsOutline size={26} color="#666666" onClick={toggleNotificationVisibility} />
                        </div>
                        <NotificationComponent visible={notificationsVisibility} toggle={toggleNotificationVisibility} />
                    </div>
                    {/* <div className="bg-white h-10 w-10 rounded-full flex justify-center items-center cursor-pointer">
                        <IoIosHelpCircleOutline size={26} color="#666666"/>
                    </div> */}
                </div>
            </div>
            <div className="bg-white rounded-md p-5 flex text-darkGray flex-col gap-5">
                <div className="flex-1 flex gap-2 justify-between items-center">
                    <label 
                        className="text-darkGray font-medium text-base" 
                        htmlFor="selectedBotVoice">* Bot Audio Voice</label>
                    <div className='flex flex-col items-end'>
                        <Dropdown
                            checkmark={true} 
                            optionLabel="label"  
                            options={botVoices}  
                            value={selectedBotVoice}
                            highlightOnSelect={false}
                            placeholder="Select Bot Voice"
                            defaultValue={selectedBotVoice}
                            onChange={(e) => setBotVoice(e.value)}  
                            className="h-10 border-l_border border-[1px]" 
                            pt={{
                                itemLabel: {className: 'text-sm'},
                                input: {className: 'text-sm p-2 font-semibold text-sm'},
                            }} 
                        />
                        <button 
                            onClick={playVoice.bind(this)} 
                            className='text-blue font-semibold text-xs my-2'>
                             {loadingVoiceAudio && <BounceLoader size={14} color="#3C99CA" /> }
                             <span className={`font-semibold text-xs ${!loadingVoiceAudio ? 'visible': 'hidden'}`}>Play Voice</span>
                        </button>
                    </div>
                </div>
                <div className="flex-1 flex gap-5 flex-col">
                    <label 
                        className="text-darkGray font-medium text-base" 
                        htmlFor="recordingResolution">* Select Bot Avatar</label>
                    <ul className='flex gap-10 flex-wrap'> 
                        {avatars?.map((avatar) => (
                            <li className="relative flex justify-center items-center">
                                <img 
                                    alt='avatar'
                                    src={avatar['url']}
                                    key={avatar['id']?.toString()}
                                    className={`h-24 w-24 rounded-md ${avatar.active ? 'cursor-pointer opacity-100': 'opacity-30'}`}
                                />
                                {!avatar?.active && (
                                    <div className='flex justify-center items-center absolute bottom-2'>
                                        <span className='font-medium text-xs text-center'>Available soon</span>
                                    </div>
                                    )
                                }
                            </li>
                        ))}
                    </ul>
                </div>
   
            </div>
            {/* <button 
                onClick={updateConfig}
                // disabled={!hasChanged()}
                className={`bg-blue text-white w-32 text-sm h-10 rounded-md font-semibold ${hasChanged() ? 'opacity-100': 'opacity-50'}`}>
                Update
            </button> */}
            <button 
                onClick={updateConfig}
                disabled={true}
                className='bg-blue text-white w-32 text-sm h-10 rounded-md font-semibold opacity-40'>
                Update
            </button>
        </div>
    )
}
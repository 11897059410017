import { useState } from "react";
import PropTypes from 'prop-types'
import { Tooltip } from "primereact/tooltip";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IoIosNotificationsOutline } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationComponent from "../HR/Dashboard/NotificationComponent";
import DaysRangeDropdown from "./DaysRangeDropdown";

function Header(props){
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const {dateRange, setDateRange} = props;
    const [notificationsVisibility, setNotificationVisibility] = useState(false);

    const toggleNotificationVisibility = () => {
        // setNotificationVisibility(!notificationsVisibility);
    }

    function getHeaderTitle(){
        if(pathname === '/dashboard' || pathname === '/dashboard/')
            return "Hiring dashboard"
        else if(pathname === '/dashboard/notifications')
            return "Notification"
        else if (pathname?.includes('job-description'))
            return "JD and Resumes"
        else if (pathname?.includes('user-management'))
            return "User management"
        else if (pathname?.includes('support'))
            return "Support"
    }

    const optionsVisible = () => {
        return pathname === '/dashboard' 
        || pathname === '/dashboard/' 
        ||  pathname?.includes('job-description') 
        ||  pathname?.includes('user-management')
    }

    const goBack = () => navigate(-1)

    return (
        <div className="w-full flex justify-between items-center min-h-[50px] text-primary bg-[#ECF5FA]">
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-1 text-[#161616]">
                    {pathname.includes('/notifications') && <MdKeyboardArrowLeft size={26} onClick={goBack} />}
                    <h1 className='text-xl text-[#161616] font-bold'>{getHeaderTitle()}</h1>
                </div>
                <div className={`${optionsVisible()} ? 'visible': 'hidden'} flex gap-6 text-brownGray`}>
                    <div className={`${pathname?.includes('user-management') || pathname?.includes('support') ? 'hidden' : 'visible'}`}>
                        <DaysRangeDropdown label={dateRange} setLabel={setDateRange} />
                    </div>
                    <div className="flex justify-center">
                        <div 
                            id="tooltip-notification"
                            className="bg-white h-10 w-10 rounded-full flex justify-center items-center cursor-pointer">
                            <IoIosNotificationsOutline 
                                size={26} 
                                color="#666666" 
                                onClick={toggleNotificationVisibility}
                            />
                            <Tooltip
                                position="left" 
                                content="No notifications"
                                target={`#tooltip-notification`} 
                            />
                        </div>
                        {/* <NotificationComponent visible={notificationsVisibility} toggle={toggleNotificationVisibility} /> */}
                    </div>
                    {/* <div className="bg-white h-10 w-10 rounded-full flex justify-center items-center cursor-pointer">
                        <IoIosHelpCircleOutline size={26} color="#666666"/>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

Header.propTypes = {
    dateRange: PropTypes.string,
    setDateRange: PropTypes.func
}

export default Header;
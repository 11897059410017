import React, { createContext, useState, useContext } from 'react';

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [state, setState] = useState(null);
  const [showInfoPopup, setShowInfoPopup] = useState(true);
  const [recordingTime, setRecordingTime] = useState(null);
  const [connection, setConnection] = useState(null);
  const [sessionInfo, setSessionInfo] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isBotSpeaking, setIsBotSpeaking] = useState(false);
  
  const [transcript, setTranscript] = useState(null);
  const [isFaceDetected, setIsFaceDetected] = useState(false);
  const [transcripts, setTranscripts] = useState([]);
  const [messageQueue, setMessageQueue] = useState([]);
  const [isPermissionsTested, setIsPermissionsTested] = useState(false);
  const [isPermissionsVerified, setIsPermissionsVerified] = useState(false);

  const handleBotMessages = (message) => {
    setMessageQueue((prevQueue) => [...prevQueue, message]);
  }

  const addMessageToTranscript = (data) => {
    setTranscripts(prevTranscript => [...prevTranscript, { ...data, timestamp: new Date() }]);
  };

  const getLastQuestionId = () => {
    const list =  transcripts?.filter((item) => item?.sender === 'Athmiya'); 
    return list?.length > 0 ? list[list?.length - 1]?.questionID : null;
}

  const clearTranscripts = () => {
    setTranscripts([]);
  };

  const clearMessageQueue = () => {
    setMessageQueue([])
  };

  return (
    <SessionContext.Provider 
      value={{ 
        state,
        sessionInfo,
        transcripts,
        messageQueue,
        isRecording,
        connection,
        transcript,
        isBotSpeaking,
        recordingTime,
        isPermissionsTested,
        isFaceDetected,
        showInfoPopup,
        isPermissionsVerified,
        setShowInfoPopup,
        setIsFaceDetected,
        setIsPermissionsTested,
        setRecordingTime,
        setTranscript,
        setConnection,
        setState, 
        setIsBotSpeaking,
        setIsRecording,
        setMessageQueue,
        setSessionInfo,
        clearTranscripts,
        clearMessageQueue,
        handleBotMessages,
        getLastQuestionId,
        addMessageToTranscript,
        setIsPermissionsVerified
      
    }}>{children}
    </SessionContext.Provider>
  );
};



export const useSessionContext = () => {
  return useContext(SessionContext);
};
import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';
import { BounceLoader } from 'react-spinners';
import { Rating } from '@smastrom/react-rating';
import { RESUME_STATUS } from '../../utils/constants';
import { useAuth } from '../../contextProviders/AuthContext';
import { useToast } from '../../contextProviders/ToastContext';
import defaultImage from './../../assets/images/default-interview-image.png';
import TranscriptComponent from '../../components/Report/TranscriptComponent';
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';
import AudioAnalysisReportPopup from '../../components/Report/AudioAnalysisReportPopup';
import { convertUTCToLocal, extractOverallAssessment, getRatingForBpoFlow, secondsToMinutes } from '../../utils/common';
import ReportService from '../../services/report.service';
import InterviewFeedbackPopup from '../../components/Common/InterviewFeedbackPopup';

export default function ReportOpsScreening({report}){
    const {user} = useAuth();
    const videoRef = useRef(null);
    const {showErrorMessage} = useToast();
    const [loading, setLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showTranscript, setShowTranscript] = useState(false);
    const [showAudioAnalyisReport, setShowAudioAnalyisReport] = useState(false);
    const [showInterviewFeedbackPopup, setShowInterviewFeedbackPopup] = useState(false);

    
    const opsScreening = (report && report?.ops_screening) ? report?.ops_screening[0] : report ? report : {};

    const audioAnalysis = opsScreening?.audio_analysis?.filter((item) => item?.speaker === 'SPEAKER_00')?.[0];

    const getDurationInSeconds = () => {
        if(opsScreening?.['start_time'] && opsScreening?.['end_time']){
            const startTime = new Date(opsScreening?.['start_time']);
            const endTime = new Date(opsScreening?.['end_time']);
            const durationMilliseconds = endTime - startTime;
            return durationMilliseconds / 1000;
        } 
        else if(opsScreening?.['startTime'] && opsScreening?.['endTime']){
            const startTime = new Date(opsScreening?.['startTime']);
            const endTime = new Date(opsScreening?.['endTime']);
            const durationMilliseconds = endTime - startTime;
            return durationMilliseconds / 1000;
        } 
        return 'NA';
    }

    const showAudioAnalysisReportPopup = () => {
        setShowAudioAnalyisReport(true);
    }

    const hideAudioAnalysisReportPopup = () => {
        setShowAudioAnalyisReport(false);
    }

    const extractReport = async () => {
        try {
            setLoading(true);
            const response = await ReportService.getReport(report['id'] || opsScreening['id']);
            setLoading(false);
            if(response.status === 200){
                const blob = new Blob([response.data], { type: "application/pdf" });
                const link = document.createElement("a");

                const fileName = report['candidate'] ? `${report['candidate']['Name']}-${Date.now()}.pdf` : 
                    report['candidate_name'] ? `${report['candidate_name']}-${Date.now()}.pdf` : `${Date.now()}.pdf`

                link.href = URL.createObjectURL(blob);
                link.download = fileName;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            showErrorMessage({ summary: 'Failed', detail: 'Failed to extract report'})
            setLoading(false);
        }
    }

    const getValue = (value) => {
        if(!value) return;
        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('low'))
            return <span className='font-semibold text-dr'>{value}</span>
        else if(v?.includes('medium'))
            return <span className='font-semibold text-do'>{value}</span>
        else if(v?.includes('high'))
            return <span className='font-semibold text-dg'>{value}</span>
    }

    const getTone = (value) => {
        if(!value) return;

        const v = value.toLowerCase();
        if(v?.includes('happy') || v?.includes('neutral'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('anger'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const getPace = (value) => {
        if(!value) return;

        const v = value.toLowerCase();
        if(v?.includes('fast'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('slow'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    if(!opsScreening || (typeof opsScreening == 'object' &&  Object.keys(opsScreening).length === 0)){
        return (
            <div className='flex flex-col gap-10'>
                    <h1 className='font-medium text-base'>{
                        report?.status['opsScreening'] === RESUME_STATUS.COMPLETED ? 
                            'Report is being prepared, will be available shortly.' : 'Report not initiated'
                    } 
                </h1>          
            </div>
        )
    }

    const getScoreTheme = (score) => {
        if(score >= 71) return 'text-dg';
        else if (score >= 49 && score < 71 ) return 'text-do';
        return 'text-dr';
    }

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () =>  setIsPlaying(false);

    return (
        <div className='flex flex-col gap-5'>
            {showAudioAnalyisReport && (
                <AudioAnalysisReportPopup 
                    audioAnalysis={audioAnalysis}
                    close={hideAudioAnalysisReportPopup}
                />
            )}
            {showTranscript && 
                <TranscriptComponent 
                    id={opsScreening?.id} 
                    hide={() => setShowTranscript(false)}
                />
            }
            {showInterviewFeedbackPopup && (
                <InterviewFeedbackPopup 
                    sessionId={opsScreening?.id || opsScreening?._id} 
                    hide={() => setShowInterviewFeedbackPopup(false)}
                />
            )}
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between flex-1 h-full gap-3'>
                    {opsScreening?.['video_path'] && (
                        <ReactPlayer
                            ref={videoRef}
                            controls
                            pip={false}
                            playing={isPlaying}
                            onPlay={handlePlay}
                            onPause={handlePause}
                            className="react-player flex-1"
                            url={opsScreening?.video_path}
                        />
                    )}
                    {!opsScreening?.['video_path'] && (
                        <div  className="react-player flex-1 flex-col-reverse flex gap-4 relative">
                            <img 
                                alt='default'
                                src={defaultImage}
                                className='h-full rounded-md'
                            />
                            <div className='absolute bottom-20 z-50 px-3 text-center flex justify-center items-center w-full'>
                                <p className='text-white font-bold text-center text-base'>Video of the interview is not available</p>
                            </div>
                        </div>
                    )}
                    <div className='flex flex-col gap-4 items-start flex-1'>
                        <div className='flex flex-col gap-4 py-5 items-center w-full'>
                            <Rating 
                                readOnly
                                color='#B99000' 
                                cancel={false} 
                                style={{width: 120}}
                                value={getRatingForBpoFlow(opsScreening?.total_score, user?.['organization']).value} 
                            />
                            <h2 className={`text-sm font-semibold ${getScoreTheme(opsScreening?.total_score)}`}>
                                {getRatingForBpoFlow(opsScreening?.total_score, user?.['organization']).title}
                            </h2>
                        </div>
                        <div className={`flex items-center gap-2 text-primary ${opsScreening?.start_time ? 'visible': 'hidden'}`}>
                            <h3 className='font-semibold'>Date of the interview :</h3>
                            <span className=''>{opsScreening?.start_time ? convertUTCToLocal(opsScreening?.start_time) : 'Not captured' }</span>
                        </div>
                        <div className={`flex items-center gap-2 text-primary ${opsScreening?.duration ? 'visible': 'hidden'}`}>
                            <h3 className='font-semibold'>Interview Duration:</h3>
                            <span className=''>{secondsToMinutes(getDurationInSeconds())}</span>
                        </div>
                        {/* <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Tab switches count:</h3>
                            <span className=''>{opsScreening?.anomalies_data?.tab_switches_count}</span>
                        </div>
                        <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Full Screen Exits Count:</h3>
                            <span className=''>{opsScreening?.anomalies_data?.full_screen_exits_count}</span>
                        </div> */}
                        {/* <div className='flex gap-2 text-primary'>
                            <h3 className='font-semibold'>Anomalies detected:</h3>
                            <ReactMarkdown>{opsScreening?.anomalies}</ReactMarkdown>
                        </div> */}
                        {/* <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Facial Expression:</h3>
                            <span className=''>{opsScreening?.anomalies_data?.facial_expression}</span>
                        </div> */}
                        {/* <div className='flex items-center gap-3'>
                            <p className='font-bold text-base'>Transcript of the interview</p>
                            <CgTranscript 
                                size={32}
                                className="cursor-pointer"
                                onClick={() => setShowTranscript(!showTranscript)}
                            />
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='flex items-center gap-5'>
                <button
                    onClick={() => setShowTranscript(!showTranscript)}
                    className='border text-xs h-9 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary'>
                    View Transcript
                </button>
                {/* <button
                    disabled={loading}
                    onClick={extractReport} 
                    className={`border h-9 w-36 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary ${report?.total_score > 0 ? 'visible': 'hidden'}`}>
                    {loading ? <BounceLoader size={26} color="#2B4360" /> : <span className="text-xs">Extract Report</span>}
                </button> */}
                <button
                    onClick={() => setShowInterviewFeedbackPopup(true)} 
                    className={`border h-9 px-6 text-xs flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary ${!opsScreening?.['report_feedback']?.status ? 'visible': 'hidden'}`}>
                    Next round status
                </button>
            </div>
            {opsScreening?.summary && (
                <div>
                    <h4 className='text-xl font-bold'>Summary</h4>
                    <p className='text-primary my-3'>{opsScreening?.summary}</p>
                </div>
            )}
            <div className={`flex flex-col gap-5 ${audioAnalysis?.['summary'] ? 'visible': 'hidden'} gap-2`}>
                <div className='flex gap-4'>
                    <h2 className='text-xl font-bold'>Communication Skills</h2>
                    <button 
                        onClick={showAudioAnalysisReportPopup} 
                        className='font-medium text-blue'>
                        Show more
                    </button>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className={`flex mt-2 flex-col gap-3 ${extractOverallAssessment(audioAnalysis?.['summary']) ? 'visible': 'hidden' }`}>
                        {extractOverallAssessment(audioAnalysis?.['summary']) && 
                            <CustomReactMarkdown data={extractOverallAssessment(audioAnalysis?.['summary'])}/>
                        }
                    </div>
                    <div className="flex flex-wrap gap-5">
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.tone ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Tone : </h2>
                            {getTone(audioAnalysis?.tone)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.pace || 'Medium' ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Pace : </h2>
                            {getPace(audioAnalysis?.pace || 'Medium')}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.speech_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Speech Quality : </h2>
                            {getValue(audioAnalysis?.speech_quality)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_volume ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Volume : </h2>
                            {getValue(audioAnalysis?.audio_volume)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Quality : </h2>
                            {getValue(audioAnalysis?.audio_quality)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`flex flex-col gap-3 ${opsScreening?.['report_feedback']?.status ? 'visible': 'hidden'}`}>
                <div className='flex gap-4 items-center'>
                    <h1 className='text-xl font-bold'>Recruiter feedback on next round</h1>
                    <div className={`text-sm h-7 px-3 rounded-full flex justify-center items-center font-semibold ${opsScreening['report_feedback']?.status === 'SELECTED' ? 'bg-dg': opsScreening['report_feedback']?.status === 'REJECTED' ? 'bg-dr': 'bg-do'}`}>
                        <span className={`text-xs ${opsScreening['report_feedback']?.status === 'SELECTED' ? 'text-lgg': opsScreening['report_feedback']?.status === 'REJECTED' ? 'text-lr': 'text-lo'}`}>{opsScreening['report_feedback']?.status}</span>
                    </div>
                </div>
                {opsScreening['report_feedback']?.feedback && <p className='text-primary'>{opsScreening['report_feedback']?.feedback}</p>}
            </div>
            <div className={`flex flex-col gap-3 ${opsScreening['candidate_feedback'] && opsScreening['candidate_feedback']?.rating >= 1 ? 'visible': 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Candidate Feedback</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={opsScreening['candidate_feedback']?.rating} 
                    />
                </div>
                <p className='text-primary'>{opsScreening['candidate_feedback']?.message}</p>
            </div>
        </div>
    )
}
import React, { createContext, useState, useContext } from "react";

const CodeContext = createContext();

export const useCode = () => useContext(CodeContext);

export function CodeProvider({ children }) {
    const [code, setCode] = useState(null);

    return (
        <CodeContext.Provider value={{ code, setCode }}>
            {children}
        </CodeContext.Provider>
    );
}

import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext({
  user: null,
  token: null,
  dateRange: null,
  login: (user, accessToken, refreshToken) => {},
  logout: () => {}
});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [dateRange, setDateRange] = useState("Last 1 year");

  useEffect(() => {
    // Load user/token from session storage on initial render
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const dateRange = localStorage.getItem('dateRange');
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
    if (storedUser && refreshToken && accessToken) {
      setUser(storedUser);
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      if(!dateRange)
        setDateRange("Last 1 year")
      else setDateRange(dateRange);
    }
  }, []);

  const changeAnalyticDateRange = (dateRange) => {
    setDateRange(dateRange);
    localStorage.setItem('dateRange', dateRange);
  }

  const login = (user, accessToken, refreshToken) => {
    setUser(user);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('accessToken', JSON.stringify(accessToken));
    localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
  };

  const logout = () => {
    setUser(null);
    setAccessToken(null);
    setRefreshToken(null);
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      setUser, 
      login, 
      logout,
      accessToken, 
      refreshToken, 
      dateRange,
      changeAnalyticDateRange 
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};

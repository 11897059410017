import { useEffect, useState } from "react"
import { MdClear } from "react-icons/md";
import SessionService from "../../services/session.service";
import LoadingComponent from "../Common/LoadingComponent";
import ConsoleHelper from "../../utils/consoleHelper";

export default function TranscriptComponent({hide, id}){
    const [transcript, setTranscript] = useState([]);
    const [loading, setLoading] = useState(false);
 
    useEffect(() => {
        async function fetchTranscript(){
            try {
                setLoading(true);
                const response = await SessionService.getTranscript(id);
                setLoading(false)
                if(response.status === 200){
                    ConsoleHelper.log(response.data.transcribe)
                    setTranscript(response.data.transcribe)
                }
            } catch (error) {
                setLoading(false)
                ConsoleHelper.error(JSON.stringify(error));
            }
        }
        fetchTranscript();
    }, [])

    return (
        <div className="h-screen w-screen z-50 absolute top-0 left-0 flex justify-center items-center bg-transparent">
            <div className="flex justify-center items-center h-[100%] relative w-[90%] xl:w-1/2">
                <div className="h-9 w-9 rounded-full bg-white absolute top-7 shadow-md z-20 -right-4 text-primary flex justify-center items-center cursor-pointer">
                    <MdClear size={22} onClick={hide} />
                </div>
                <div className="bg-white rounded-md w-full h-[90%] overflow-hidden relative flex flex-col">
                    <div className="p-5 sticky top-0 shadow-md">
                        <h1 className="text-xl font-bold">Conversation</h1>
                    </div>
                    <div className={`py-5 ${loading && transcript?.length === 0 ? 'visible': 'hidden'}`}>
                        <LoadingComponent />
                    </div>
                    <p className={`${!loading && transcript?.length === 0 ? 'visible': 'hidden'} text-center py-5 text-base font-semibold`}>No transcript</p>
                    <div className={`${transcript?.length ? 'visible': 'hidden'} flex overflow-y-scroll h-full px-5`}>
                        <ul className="flex flex-col">
                            {transcript?.map((item, index) => (
                                <li 
                                    key={index?.toString()} 
                                    className="flex flex-col gap-2 border-b-lightBlue border-b-[1px] py-5">
                                    <div className="flex">
                                        <h3 className="w-20 font-semibold">Athmiya:</h3>
                                        <p className="flex-1">{item.assistant.content?.question || item.assistant.content}</p>
                                        {/* <span className="font-semibold text-xs">{moment.unix(item.assistant.timestamp).format('DD-MM hh:mm:ss A')}</span> */}
                                    </div>
                                    <div className={`flex flex-1 ${item?.user?.content?.length > 0 ? 'visible': 'hidden'}`}>
                                        <h3 className="w-20 font-semibold">User: </h3>
                                        <p className={`flex-1 ${!item?.is_coding_question ? 'visible': 'hidden'}`}>
                                            {item?.user?.content[0] || item?.user?.content[1]}
                                        </p>
                                        <div className={`${item?.is_coding_question ? 'visible flex-1': 'hidden'}`}>
                                            <pre className="flex-1 w-full text-[.7rem]">
                                                <code className='flex-1'>{item?.user?.content[0] || item?.user?.content[1]}</code>
                                            </pre>   
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
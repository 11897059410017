import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import Header from "../../../components/Common/Header";
import ConsoleHelper from "../../../utils/consoleHelper";
import { usePageData } from "../../../contextProviders/PagesDataContext";
import OrganizationService from "../../../services/organization.service";
import AdminDashboardAnalyticsSection from "../../../components/Admin/Dashboard/AdminDashboardAnalyticsSection";
import { useAuth } from "../../../contextProviders/AuthContext";

export default function AdminDashboard(){
    const navigate = useNavigate();
    const {updatePageData } = usePageData();

    const [loading, setLoading] = useState(false);
    const [selectedJD, setSelectedJD] = useState(null);
    const [organisations, setOrganisations] = useState([]);
    const [menuVisibility, setMenuVisibility] = useState(null);

    const {dateRange, changeAnalyticDateRange} = useAuth();

    useEffect(() => {
        updatePageData('jd-page', {});
        updatePageData('jd-resumes', {});
    }, []);

    const handleMenuVisibility = (value) => {
        if(value !== menuVisibility)
            setMenuVisibility(value)
    }

    const handleSelectedJd = (value) => {
        if(value !== selectedJD)
            setSelectedJD(value);
    }

    const onRowClick = ({data}) => {
        navigate(`/dashboard/organizations/${data._id}/jds`)
    }

    useEffect(() => {
        async function getOrganizations(){
            try {
                setLoading(true);
                const response = await OrganizationService.getOrganizations(1, 20);
                setLoading(false);
                if(response.status === 200){
                    const {data} = response.data;
                    setOrganisations(data)
                }
            } catch (error) {
                setLoading(false);
                ConsoleHelper.error(error?.response?.data?.message)
            }
        }
        getOrganizations();
    }, []);


    return (
        <div
            onClick={handleSelectedJd.bind(this, null)} 
            className="flex flex-col h-full gap-5 p-5">
            <Header 
                dateRange={dateRange} 
                setDateRange={changeAnalyticDateRange}
            />
            <div className="w-full flex flex-col gap-10">
                <AdminDashboardAnalyticsSection
                    dateRange={dateRange}
                    handleSelectedJd={handleSelectedJd}
                    handleMenuVisibility={handleMenuVisibility}
                />

                <div className="flex flex-col gap-3">
                    <h1 className='text-xl text-[#161616] font-bold'>Organization List</h1>
                    <div 
                        className="bg-white rounded">
                        <DataTable 
                            value={organisations}
                            loading={loading}
                            emptyMessage="No Organisations available"
                            onRowClick={onRowClick}
                            rowClassName='bg-white hover:text-blue cursor-pointer'>
                            <Column 
                                header="" 
                                headerClassName='w-24'
                                body={({logo}) => (
                                    <div>
                                        <img
                                            src={logo}
                                            alt='logo'
                                            className='h-10 w-10 rounded-full'
                                        />
                                    </div>
                                )}
                            />
                            <Column 
                                field="organizationID" 
                                header="Id" 
                                className='text-sm 2xl:text-base'
                                headerClassName='text-sm 2xl:text-base w-40' 
                            />
                            <Column 
                                field="name" 
                                header="Name" 
                                className='text-sm 2xl:text-base'
                                headerClassName='text-sm 2xl:text-base' 
                            />
                            <Column 
                                header="Website" 
                                body={({website}) => (
                                    <div className=''>
                                        <span 
                                            onClick={() => window.open(website, '_blank')}
                                            className='underline line-clamp-1'>
                                            {website}
                                        </span>
                                    </div>
                                )}
                                className='text-sm 2xl:text-base w-1/3'
                                headerClassName='text-sm 2xl:text-base' 
                            />
                            <Column 
                                field="userCount" 
                                header="Hr's" 
                                body={({userCount}) => (
                                    <div className='h-8 w-8 flex items-center justify-center bg-primary rounded-full'>
                                        <span className="text-white font-medium">{userCount}</span>
                                    </div>
                                )}
                                className='text-sm 2xl:text-base'
                                headerClassName='text-sm 2xl:text-base' 
                            />
                            <Column 
                                field="industry" 
                                header="Industry" 
                                className='text-sm 2xl:text-base'
                                headerClassName='text-sm 2xl:text-base' 
                            />
                            {/* <Column 
                                field="hrTeam" 
                                header="HR team" 
                                className='text-sm 2xl:text-base'
                                headerClassName='text-sm 2xl:text-base' 
                            /> */}
                            <Column 
                                field="status" 
                                header="Status" 
                                className='text-sm 2xl:text-base'
                                headerClassName='text-sm 2xl:text-base bg-white' 
                            />
                            {/* <Column 
                                field="noOfHiring" 
                                header="No. of hiring" 
                                className='text-sm 2xl:text-base'
                                headerClassName='text-sm 2xl:text-base bg-white' 
                            /> */}
                            {/* <Column 
                                body={options}
                                className='text-sm 2xl:text-base'
                                headerClassName='text-sm 2xl:text-base bg-white' 
                            /> */}
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}

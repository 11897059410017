import { Route, Routes } from "react-router-dom";
import JDDetailsPage from "../pages/HR/Job/JDDetailsPage";
import OrganisationJdPage from "../pages/HR/Job/OrganizationJDPage";

export default function OrganisationJdsRoute(){
    return (
        <Routes>
            <Route index path="" element={<OrganisationJdPage />} />
            <Route path=":id" element={<JDDetailsPage />} />
        </Routes>
    )
}
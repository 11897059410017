import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { useJDInfo } from "../../../contextProviders/JDInfoContext";
import CheckboxComponent from "../../../components/Common/CheckboxComponent";
import { Dropdown } from 'primereact/dropdown';

const SKILL_OPTIONS = [
    {label: 'Primary', value: 'primary'}, 
    {label: 'Secondary', value: 'secondary'}
]

function JDManageEditSkills() {
    const { getJDInfo, changeJDInfo } = useJDInfo();
    const jdInfo = getJDInfo();

    const [skills, setSkills] = useState([]);
    const [primarySkills, setPrimarySkills] = useState([]);
    const [secondarySkills, setSecondarySkills] = useState([]);
    const maxYear = Math.max(...jdInfo['experience'].match(/\d+/g).map(Number));

    useEffect(() => {
        const skills1 = convertAndCombineSkills(jdInfo['primarySkills'] || []);
        const skills2 = convertAndCombineSkills(jdInfo['secondarySkills'] || []);

        setPrimarySkills(skills1);
        setSecondarySkills(skills2);

        const list = [
            ...skills1?.map(item => ({ ...item, isPrimary: true })) || [],
            ...skills2?.map(item => ({ ...item, isPrimary: false })) || []
        ];
        setSkills(list);

    }, [])

    const handleSkillChange = (index, value, field) => {
        const updatedSkills = skills.map((skill, i) => 
            i === index 
                ? { ...skill, [field]: field === 'isPrimary' ? value === 'primary' : value } 
                : skill
        );

        const primarySkills = updatedSkills
            ?.filter((item) => item.isPrimary)
            .map(({ isPrimary, ...rest }) => rest);

        const secondarySkills = updatedSkills
            ?.filter((item) => !item.isPrimary)
            .map(({ isPrimary, ...rest }) => rest);

        changeJDInfo({ 
            primarySkills,
            secondarySkills
        });

        setSkills(updatedSkills);
    };

    const getTotalWeightage = () => {
        return skills.reduce((obj1, obj2) => obj1 + parseInt(obj2.weightage), 0);
    }  

    function convertAndCombineSkills(stringArray, mandatory = false, defaultExperience = 0, defaultWeightage = 0) {
        const skillMap = {};
    
        if(stringArray && stringArray?.length > 0){
            stringArray?.forEach(skill => {
                if(skill?.name){
                    skillMap[skill.name] = {
                        name: skill['name'],
                        mandatory: skill['mandatory'],
                        weightage: skill['weightage'],
                        experience: skill['experience']
                    };
                }
                else {
                    skillMap[skill] = {
                        name: skill,
                        weightage: defaultWeightage,
                        mandatory: mandatory,
                        experience: defaultExperience
                    };
                }
            });
        }
    
        // Convert the map back to an array
        return Object.values(skillMap);
    }  

    return (
        <div className="h-full flex flex-col">
            <div className="text-darkGray rounded-md flex flex-col">
                <div className="flex gap-2 flex-col bg-white p-5">
                    <h2 className="text-xl font-medium text-primary">Mandatory Skills</h2>
                    <div className="flex mt-5">
                        <div className="flex-1">
                            <h3 className="font-medium text-base text-darkGray">Name</h3>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <h3 className="font-medium text-base text-darkGray">Skill Type</h3>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <h3 className="font-medium text-base text-darkGray">Weightage (0 - 100 %)</h3>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <h3 className="font-medium text-base text-darkGray">Mandatory</h3>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <h3 className="font-medium text-base text-darkGray">Min. Experience (Years)</h3>
                        </div>
                    </div>
                    <ul className={`flex flex-col gap-5 ${skills?.length > 0 ? 'visible': 'hidden'} mt-3`}>
                        {skills.map((item, index) => (
                            <li key={index} className="flex justify-between items-center">
                                <div className="flex-1"><span>{item.name}</span></div>
                                <div className="flex-1 flex justify-center items-center gap-3">
                                    <Dropdown 
                                        checkmark={true}
                                        optionLabel="label"
                                        options={SKILL_OPTIONS}  
                                        highlightOnSelect={false}
                                        placeholder="Select Skill Type" 
                                        className="h-10 border-l_border border-[1px]"
                                        value={item['isPrimary'] ? 'primary': 'secondary'} 
                                        defaultValue={item['isPrimary'] ? SKILL_OPTIONS[0]: SKILL_OPTIONS[1]}
                                        onChange={(e) => handleSkillChange(index, e.value, 'isPrimary')}  
                                        pt={{
                                            root: { className: 'flex justify-center items-center px-2' },
                                            input: {className: 'text-sm w-28'},
                                            itemLabel: {className: 'text-sm'},
                                            trigger: { className: 'h-3 w-3' }
                                        }} 
                                    />
                                </div>
                                <div className="flex-1 flex justify-center items-center">
                                    <InputText
                                        min="0"
                                        max="100"
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        value={item.weightage}
                                        onChange={(e) => {
                                            let value = parseInt(e.target.value, 10);
        
                                            if (isNaN(value) || value < 1)
                                                value = 0;

                                            handleSkillChange(index, value, 'weightage');
                                        }}
                                        className="border w-10 rounded-md font-medium text-sm border-l_border h-9 px-1 text-center"
                                    />
                                </div>
                                <div className="flex-1 flex justify-center items-center">
                                    <CheckboxComponent
                                        value={item.mandatory}
                                        setValue={(label, value) => handleSkillChange(index, value, 'mandatory')}
                                    />
                                </div>
                                <div className="flex-1 flex justify-center items-center">
                                    <InputText
                                        min="0"
                                        max={maxYear}
                                        disabled={!item['mandatory']}
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        value={item.experience}
                                        onChange={(e) => {
                                            let value = parseInt(e.target.value, 10);
                                            if (isNaN(value) || value < 1) {
                                                value = 0;
                                            } else if (value > maxYear) {
                                                value = maxYear;
                                            }
                                            handleSkillChange(index, value, 'experience')
                                        }}
                                        className="border w-10 rounded-md font-medium text-sm border-l_border h-9 px-1 text-center"
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                    <span className={`${skills?.length === 0 ? 'visible': 'hidden'} py-3 text-sm`}>
                        No skills available
                    </span>
                </div>

                <div className={`flex py-2 ${skills?.length > 0 ? 'visible': 'hidden'}`}>
                    <div className="flex-1" />
                    <div className="flex-1 flex justify-center items-center" />
                    <div className="flex-1 flex justify-center items-center">
                        <p className="font-medium text-base text-darkGray">Total weightage: 
                            <span className={`ml-1 font-semibold ${getTotalWeightage() > 100 ? 'text-dr': getTotalWeightage() === 100 ? 'text-dg' : 'text-blue'}`}>{getTotalWeightage()} %</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-center items-center" />
                    <div className="flex-1 flex justify-center items-center" />
                </div>
            </div>
        </div>
    )
}

JDManageEditSkills.propTypes = {
    setActiveTabIndex: PropTypes.func
}

export default JDManageEditSkills;

import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { MdClear } from "react-icons/md";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { IoMdAddCircleOutline } from "react-icons/io"
import { InputNumber } from 'primereact/inputnumber';
import { useJDInfo } from "../../../contextProviders/JDInfoContext";
import CheckboxComponent from "../../../components/Common/CheckboxComponent";
import { useAuth } from "../../../contextProviders/AuthContext";

const interviewDifficulties = [
    { name: "Easy", code: "easy"},
    { name: "Medium", code: "medium"},
    { name: "Advanced", code: "advanced"},
    { name: "Expert", code: "expert"},
]

const OPS_ANSWER_TYPE = [
    { name: "Text", code: "text"},
    { name: "Voice", code: "voice"},
]

function JDOpsQAsEditSection(){
    const {user} = useAuth();

    const { changeJDInfo, getJDInfo } = useJDInfo();
    const jdInfo = getJDInfo();

    const opsScreeningQAs = jdInfo?.["opsScreeningQAs"] || [];

    const [interviewDuration, setInterviewDuration] = useState(jdInfo?.['duration'] != null ? jdInfo?.['duration'] : user?.email?.includes('alorica') ? 15 : 30);

    const [evaluationThreshold, setEvaluationThreshold] = useState(jdInfo?.['evaluationThreshold'] || 10);
    const [isAnomalyDetectionRequired, setIsAnomalyDetectionRequired] = useState(jdInfo?.['detectAnomaly'] || false);
    
    const [isSummarizedJDRequired, setIsSummarizedJDRequired] = 
        useState(jdInfo?.['startWithSummary'] == null ? true : jdInfo?.['startWithSummary']);

    const initialInterviewDifficultyValue = 
        typeof jdInfo?.['difficultyLevel'] === "string" ? { name: jdInfo?.['difficultyLevel']?.replace(/\w/, c => c.toUpperCase()), code: jdInfo?.['difficultyLevel'] } :  
        typeof jdInfo?.['difficultyLevel'] === "object" ? jdInfo?.['difficultyLevel']  : 
            interviewDifficulties[1];

    const [interviewDifficulty, setInterviewDifficulty ] = useState(initialInterviewDifficultyValue);

    const initialBpoOpsAnswerTypeValue = 
    typeof jdInfo?.['bpoOpsAnswerType'] === "string" ? { name: jdInfo?.['bpoOpsAnswerType']?.replace(/\w/, c => c.toUpperCase()), code: jdInfo?.['bpoOpsAnswerType'] } :  
    typeof jdInfo?.['bpoOpsAnswerType'] === "object" ? jdInfo?.['bpoOpsAnswerType']  : 
        OPS_ANSWER_TYPE[1];
        
    const [bpoOpsAnswerType, setBpoOpsAnswerType ] = useState(initialBpoOpsAnswerTypeValue);

    const [isCheckForInterpersonalSkills, setIsCheckForInterpersonalSkills] = useState(jdInfo?.['isCheckForInterpersonalSkills'] || false);

    const [questionsAnswers, setQuestionsAnswers] = useState(opsScreeningQAs || [{question: ''}]);
    const addQuestionAnswer = () => setQuestionsAnswers([...questionsAnswers, {question: ''}]);

    const insertQuestion = (questionIndex, text) => {
        const updatedList = questionsAnswers?.map((item, index) => {
            if(index === questionIndex)
                return {question: text}
            return item;
        });
        setQuestionsAnswers(updatedList);
        changeJDInfo({ opsScreeningQAs: updatedList});
    }

    const removeQuestion = (questionIndex) => {
        const newList = questionsAnswers?.filter((item, index) => index !== questionIndex);
        setQuestionsAnswers(newList);
        changeJDInfo({ opsScreeningQAs: newList});
    };


    const insertAnswer = (answerIndex, text) => {
        const updatedList = questionsAnswers?.map((item, index) => {
            if(index === answerIndex)
                return {question: item?.question}
            return item;
        });
        setQuestionsAnswers(updatedList);
        changeJDInfo({ opsScreeningQAs: updatedList});
    }

    const handleChanges = (key, value) => {
        if(key === 'detectAnomaly') setIsAnomalyDetectionRequired(value)
        else if (key === 'startWithSummary') setIsSummarizedJDRequired(value)
        else if (key === 'duration') setInterviewDuration(value)
        else if (key === 'difficultyLevel') setInterviewDifficulty(value)
        else if (key === 'bpoOpsAnswerType') setBpoOpsAnswerType(value)
        else if (key === 'evaluationThreshold') setEvaluationThreshold(value)
        else if (key === 'isCheckForInterpersonalSkills') setIsCheckForInterpersonalSkills(value)
        changeJDInfo({[key]: value})
    }

    useEffect(() => {
        if(user?.email?.includes('alorica'))
            setInterviewDuration(15);
    }, [user?.email])

    return (
        <div className="h-full flex flex-col gap-10">
            <div className="flex flex-col p-5 bg-white rounded-md gap-10">
                <div className="flex flex-col gap-5">
                    <div className="flex gap-5 items-center">
                        <span className="text-base w-full">* Anomaly Detection required</span>
                        <CheckboxComponent
                            label="detectAnomaly" 
                            setValue={handleChanges}
                            value={isAnomalyDetectionRequired}
                        />
                    </div>
                    <div className="flex gap-5 items-center">
                        <span className="text-base w-full">* Check for communication / Interpersonal skills</span>
                        <CheckboxComponent 
                            setValue={handleChanges}
                            label="isCheckForInterpersonalSkills"
                            value={isCheckForInterpersonalSkills}
                        />
                    </div>
                    <div className="flex gap-5 items-center">
                        <span className="text-base w-full">* Summarize the JD at the beginning of the interview</span>
                        <CheckboxComponent
                            label="startWithSummary" 
                            setValue={handleChanges}
                            value={isSummarizedJDRequired}
                        />
                    </div>
                    <div className="flex gap-5 items-center">
                        <span className="text-base w-full">* Interview Duration (in minutes)</span>
                        {/* <InputText 
                            value={interviewDuration}
                            onChange={(e) => handleChanges('duration', e.target.value)}
                            className="border h-8 w-10 px-1 text-center"  
                        /> */}
                        <InputNumber
                            min={30}
                            max={90}  
                            value={interviewDuration}
                            onValueChange={(e) => handleChanges('duration', e.value)} 
                            className="h-10 text-center border-[1px] rounded-md text-sm"
                        />
                    </div>
                    <div className="flex gap-5 items-center">
                        <div className="flex flex-col  w-full">
                            <span className="text-base">* Threshold in evaluation scoring (10-30 %)</span>
                            <span className="text-do text-sm">Note: How lenient you want us to be in scoring</span>
                        </div>
                        <InputNumber  
                            min={10}
                            max={30}
                            value={evaluationThreshold}
                            className="h-10 text-center border-[1px] rounded-md text-sm"
                            onValueChange={(e) => handleChanges('evaluationThreshold', e.value)} 
                        />
                    </div>
                    <div className="flex gap-5 items-center">
                        <span className="text-base w-full">* Interview questions Difficulty level (default level is medium)</span>
                        <Dropdown
                            optionLabel="name"   
                            className="border text-sm"
                            value={interviewDifficulty}
                            placeholder="Questions Difficulty"
                            onChange={(e) => handleChanges('difficultyLevel', e.value)} 
                            options={interviewDifficulties} 
                            pt={{
                                root: {className: 'flex items-center pr-2'},
                                input: {className: 'text-xs h-10'},
                                item: {className: 'text-xs h-10'},
                                clearIcon: {className: 'text-xs h-3 w-3'},
                                trigger: {className: 'text-xs h-3 w-3'}
                            }}
                        />
                    </div>
                </div>
                <div className="flex gap-5 items-center">
                        <span className="text-base w-full">* BPO OPS Answer Type</span>
                        <Dropdown
                            optionLabel="name"   
                            className="border text-sm"
                            value={bpoOpsAnswerType}
                            placeholder="BPO OPS Answer Type"
                            onChange={(e) => handleChanges('bpoOpsAnswerType', e.value)} 
                            options={OPS_ANSWER_TYPE} 
                            pt={{
                                root: {className: 'flex items-center pr-2'},
                                input: {className: 'text-xs h-10'},
                                item: {className: 'text-xs h-10'},
                                clearIcon: {className: 'text-xs h-3 w-3'},
                                trigger: {className: 'text-xs h-3 w-3'}
                            }}
                        />
                </div>
                <div className="text-darkGray flex flex-col gap-5">
                    <h2 className="font-medium text-base text-darkGray">Add any additional questions to be asked (OPTIONAL)</h2>
                    <div className={`${questionsAnswers?.length > 0 ? 'visible': 'hidden'} flex flex-col gap-8`}>
                        {questionsAnswers?.map((item, index) => (
                            <div 
                                key={index?.toString()}
                                className="flex gap-2 shadow-md p-5 rounded-md justify-center items-center">
                                <div className="flex flex-col gap-2 flex-1">
                                    <div 
                                        className="flex flex-col gap-2">
                                        <h1 className="font-semibold text-xs">Question {index + 1}</h1>
                                        <InputText
                                            value={item.question}
                                            placeholder="Enter question"
                                            className="border border-[#EAECF0] h-10 px-2"
                                            onChange={(event) => insertQuestion(index, event.target.value)}
                                        />
                                    </div>
                                    {/* <div 
                                        key={index?.toString()}
                                        className="flex flex-col gap-2">
                                        <h1 className="font-semibold text-xs">Answer {index + 1}</h1>
                                        <InputText
                                            value={item.answer}
                                            placeholder="Enter answer"
                                            className="border border-[#EAECF0] h-10 px-2"
                                            onChange={(event) => insertAnswer(index, event.target.value)}
                                        />
                                    </div> */}
                                </div>
                                <div 
                                    onClick={removeQuestion.bind(this, index)}
                                    className="h-8 w-8 shadow-md justify-center flex items-center rounded-full cursor-pointer hover:bg-primary hover:text-white">
                                    <MdClear size={18}/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div 
                        onClick={addQuestionAnswer}
                        className="border-blue border cursor-pointer text-blue px-2 h-10 rounded font-medium text-zs flex items-center gap-4 w-32">
                        <IoMdAddCircleOutline size={20}/>
                        <span>Add Q&A</span>
                    </div>
                </div>
            </div>
            {/* <div className="flex justify-between">
                <button 
                    onClick={() => setActiveTabIndex(1)}
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-32 mt-10'>
                    Back
                </button>
                <button
                    onClick={() => {
                        changeJDInfo({ opsScreeningQAs: questionsAnswers});
                        setActiveTabIndex(3);
                        // onFinalizedJD();
                    }} 
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-40 mt-10'>
                    Next
                </button>
            </div> */}
        </div>
    )
}

JDOpsQAsEditSection.propTypes = {
    onFinalizedJD: PropTypes.func,
    setActiveTabIndex: PropTypes.func
}

export default JDOpsQAsEditSection;
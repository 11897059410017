import { useState } from 'react';
import MDEditor from '@uiw/react-md-editor';

export default function CodingQuestionEditor(props){
    const {addCodingQuestion, hideCodingQuestionEditor} = props;
    const [codingQuestion, setCodingQuestion] = useState(null);

    const onSubmit = () => {
        if(codingQuestion && codingQuestion?.length > 2)
            addCodingQuestion(codingQuestion);
        hideCodingQuestionEditor();
    }

    const onCancel = () => hideCodingQuestionEditor();

    return (
        <div className="min-h-screen w-screen absolute top-0 bg-transparent left-0 p-10 z-50 gap-7 flex flex-col">
            <MDEditor
                value={codingQuestion}
                onChange={setCodingQuestion}
                className='h-full'
                textareaProps={{
                    placeholder: 'Please write coding question',
                }}
                height={window.screen.height * .7}
            />
            <div className='flex justify-between items-center'>
                <button 
                    onClick={onCancel}
                    className='bg-blue min-h-10 w-40 rounded-md text-white font-medium'>
                    Cancel
                </button>
                <button 
                    onClick={onSubmit}
                    className='bg-blue min-h-10 w-40 rounded-md text-white font-medium'>
                    Submit
                </button>
            </div>
        </div>
    )
}
import { useState } from "react";
import { MdClear } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { InputText } from "primereact/inputtext";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useToast } from "../../../contextProviders/ToastContext";
import LoadingComponent from '../../../components/Common/LoadingComponent';
import ExitInterviewService from '../../../services/exitInterview.service';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export default function ExitInterviewCreate(){

    const { showErrorMessage, showSuccessMessage } = useToast();
    const navigate = useNavigate();

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false)
    
    const [jobRole, setJobRole] = useState(null);
    const [candidateName, setCandidateName] = useState(null);
    const [candidateEmail, setCandidateEmail] = useState(null);

    const [questions, setQuestions] = useState([]);
    const addQuestion = () => setQuestions([...questions, {question: ''}]);

    const goBack = () => navigate(-1);

    const insertQuestion = (questionId, event) => {
        const question = event.target.value
        const updatedList = questions?.map((item, index) => {
            if(index === questionId)
                return {question: question}
            return item;
        });
        setQuestions(updatedList);
    }

    const removeQuestion = (questionIndex) => {
        const newList = questions?.filter((item, index) => index !== questionIndex);
        setQuestions(newList);
    };


    const validate = () => {
        setError({})
        const error = {};
        if(!candidateName)
            error['candidateName'] = 'Name required';
        if(!candidateEmail)
            error['candidateEmail'] = 'Email required';
        else if (!emailRegex.test(candidateEmail))
            error['candidateEmail'] = 'Enter correct email';
        if(!jobRole || jobRole?.trim().length === 0)
            error['jobRole'] = 'Job Role required';

        if(Object.keys(error).length){
            setError(error)
            return false;
        }
        return true;
    }

    const submit =  async () => {
        try {
            if(!validate()) return;
            const requestData = {
                candidateEmail,
                candidateName,
                jobRole
            }

            if(questions?.length)
                requestData['interviewQuestions'] = questions;

            setLoading(true);
            const response = await ExitInterviewService.createExitInterview(requestData);
            setLoading(false);
            if(response.status === 201){
                showSuccessMessage({summary: 'Created', detail: "Entry successfully created"})
                navigate('/dashboard/exit-interview')
            } 

        } catch (error) {
            setLoading(false);
            showErrorMessage({
                summary: 'Failed', 
                detail: error?.response?.data?.error
            })
        }
    }

    return (
        <div className="flex flex-col h-full gap-5 px-10 pt-5">
            <div className={`${loading? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-10 rounded-md w-1/4 flex justify-center items-center">
                    <LoadingComponent />
                </div>
            </div>
            <div
                className="flex items-center gap-4 text-darkGray">
                <div className="flex items-center gap-2 cursor-pointer">
                    <MdKeyboardArrowLeft onClick={goBack} size={26}/>
                    <h1 className='text-xl text-primary font-bold'>Initiate Exit Interview</h1>
                </div>
                {/* <span onClick={() => setShowJDPopup(true)} className="text-blue underline text-sm cursor-pointer">View JD</span> */}
            </div>
            <div className="bg-white p-5 rounded-md">
                <div className="bg-white rounded gap-10 flex flex-col mt-2">
                    <h2 className='font-bold'>Employee Details</h2>
                    <div className="flex gap-5">
                        <div className="flex-col flex gap-2 w-1/2">
                            <label className="text-darkGray font-medium text-sm" htmlFor="candidateName">Candidate Name</label>
                            <InputText 
                                id="candidateName"
                                value={candidateName}
                                placeholder="Enter Candidate Name" 
                                onChange={(e) => setCandidateName(e.target.value)}
                                className="border-l_border border-[1px] h-10 p-2 font-normal text-sm"
                            />
                            {error['candidateName'] && <span className="text-dr font-normal text-xs">{error['candidateName']}</span>}
                        </div>
                        <div className="flex flex-col gap-2 w-1/2">
                            <label className="text-darkGray font-medium text-sm" htmlFor="candidateEmail">Candidate Email</label>
                            <InputText 
                                id="candidateEmail"
                                value={candidateEmail}
                                placeholder="Enter Candidate Email" 
                                onChange={(e) => setCandidateEmail(e.target.value)}
                                className="border-l_border border-[1px] h-10 p-2 font-normal text-sm"
                            />
                            {error['candidateEmail'] && <span className="text-dr font-normal text-xs">{error['candidateEmail']}</span>}
                        </div>
                    </div>
                    <div className="flex gap-5">
                        <div className="flex flex-col gap-2 w-1/2">
                            <label className="text-darkGray font-medium text-sm" htmlFor="jobRole">Job Role</label>
                            <InputText 
                                id="jobRole"
                                value={jobRole}
                                placeholder="Enter job role" 
                                onChange={(e) => setJobRole(e.target.value)}
                                className="border-l_border border-[1px] h-10 p-2 font-normal text-sm"
                            />
                            {error['jobRole'] && <span className="text-dr font-normal text-xs">{error['jobRole']}</span>}
                        </div>
                    </div>
                </div>
                <div className="text-darkGray flex flex-col gap-5 mt-10">
                    <h2 className="font-medium text-base text-darkGray">Additional screening questions relevant to exit interview (Optional)</h2>
                    <div className={`${questions?.length > 0 ? 'visible': 'hidden'} flex flex-col`}>
                        {questions?.map((item, index) => (
                            <div 
                                key={index?.toString()}
                                className="flex gap-2 py-3 rounded-md justify-center items-center">
                                <div className="flex flex-col gap-2 flex-1">
                                    <div 
                                        className="flex flex-col gap-2">
                                        <h1 className="font-semibold text-xs">Question {index + 1}</h1>
                                        <InputText
                                            value={item.question}
                                            placeholder="Enter question"
                                            className="border border-[#EAECF0] h-10 px-2 font-normal text-sm"
                                            onChange={insertQuestion.bind(this, index)}
                                        />
                                    </div>
                                </div>
                                <div 
                                    onClick={removeQuestion.bind(this, index)}
                                    className="h-8 w-8 shadow-md justify-center flex items-center rounded-full cursor-pointer hover:bg-primary hover:text-white">
                                    <MdClear size={18}/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div 
                        onClick={addQuestion}
                        className="cursor-pointer text-blue px-2 h-9 rounded font-medium text-sm flex items-center gap-2 w-36">
                        <IoMdAddCircleOutline size={20}/>
                        <span>Add {`${questions?.length > 0 ?  'More' : 'Question'}`}</span>
                    </div>
                </div>
                <div className="flex justify-between">
                <button
                    onClick={submit} 
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-40 mt-10'>
                    Submit
                </button>
            </div>
            </div>
        </div>

    )
}
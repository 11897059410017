// export const SCREENING_TYPE = {
//     INITIAL_SCREENING: 'INITIAL_SCREENING',
//     TECHNICAL_SCREENING: 'TECHNICAL_SCREENING'
// }

// export const STORAGE_CONTAINERS = {
//     jds: 'jds',
//     profiles: 'profiles',
//     resumes: 'resumes',
// }


export const EMPLOYMENT_TYPES = [
    {name: 'Full-time', code: 'Full-time'},
    {name: 'Part-time', code: 'Part-time'},
    {name: 'Contract', code: 'Contract'},
    {name: 'Internship', code: 'Internship'},
]


export const EXPERIENCES = [
    { name: "0-3 years", code: "0-3 years", range: [0, 3]},
    { name: "3-6 years", code: "3-6 years", range: [3, 6]},
    { name: "6-9 years", code: "6-9 years", range: [6, 9]},
    { name: "9-12 years", code: "9-12 years", range: [9, 12]},
    { name: "12-15 years", code: "12-15 years", range: [12, 15]},
    { name: "15+ years", code: "15+ years", range: [15, 20]}
]

export const RESUME_STAGES = {
    COMPLETED: 'COMPLETED',
    ARCHIVED: 'ARCHIVED',
    IN_QUEUE: 'IN_QUEUE',
    VIDEO_PROFILING: 'VIDEO_PROFILING',
    SHORTLISTING: 'SHORTLISTING',
    EXIT_INTERVIEW: 'EXIT_INTERVIEW',
    OPS_SCREENING: 'OPS_SCREENING',
    RESUME_RECEIVED: 'RESUME_RECEIVED',
    RESUME_SCREENING: 'RESUME_SCREENING',
    INITIAL_SCREENING: 'INITIAL_SCREENING',
    BPO_HR_SCREENING: 'BPO_HR_SCREENING',
    TECHNICAL_SCREENING: 'TECHNICAL_SCREENING',
}

export const BPO_OPS_ANSWER_TYPE = {
    VOICE: 'voice',
    TEXT: 'text'
}


export const RESUME_STATUS = {
    NA: 'NA',
    FAILED: 'FAILED',
    EXITED: 'EXITED',
    PARSING: 'PARSING',
    PENDING: 'PENDING',
    SCHEDULED: 'SCHEDULED',
    COMPLETED: 'COMPLETED',
    IN_PROGRESS: 'IN_PROGRESS',
    RECOMMENDED: 'RECOMMENDED',
    INVITE_SENT: 'INVITE_SENT',
    INVITE_EXPIRED: 'INVITE_EXPIRED',
    NOT_RECOMMENDED: 'NOT_RECOMMENDED',
    ALREADY_COMPLETED: 'ALREADY_COMPLETED',
    SHORTLISTING_IN_PROGRESS: 'SHORTLISTING_IN_PROGRESS',
}

export const SESSION_STATUS = {
    FAILED: 'FAILED',
    EXITED: 'EXITED',
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
    DISCONNECT: 'DISCONNECT',
    ABNORMALLY_CLOSED: 'ABNORMALLY_CLOSED',
}

export const PROMPT_INTERVIEW_TYPES = [
    { label: "Video Profiling", value: "video-profiling" },
    { label: "HR Interview", value: "hr-interview" },
    { label: "Technical Interview", value: "technical-interview" },
    { label: "Exit Interview", value: "exit-interview" },
    { label: "BPO HR Interview", value: "bpo-hr-interview" },
    { label: "BPO OPS Text Interview", value: "bpo-ops-text-interview" },
    { label: "BPO OPS Voice Interview", value: "bpo-ops-voice-interview" }
]

export const SCREENINGS = ['INITIAL_SCREENING', 'TECHNICAL_SCREENING'];
export const GREETING_MESSAGE = `Hi {candidate_name}, I am Athmiya, AI interviewer. Let me first describe the role, {job_summary} With this context of the role, let's start the conversation.`;

import axiosInstance from ".";

function getOrganizations(page=1, limit=10){
    return axiosInstance.get(`/organization`)
}

function createOrganization(data){
    return axiosInstance.post('/organization', data)
}

function getOrganization(organizationId){
    return axiosInstance.get(`/organization/${organizationId}`)
}

function getOrganizationJds(organizationId, page=1, limit=10, sort, sortField){
    if(sort && sortField)
        return axiosInstance.get(`/jds?page=${page}&limit=${limit}&sort=${sort}&sortField=${sortField}`)

    return axiosInstance.get(`/organization/${organizationId}/jds?page=${page}&limit=${limit}`)
}

function updateOrganization(organizationId, data){
    return axiosInstance.put(`/organization/${organizationId}`, data)
}

function deleteOrganization(organizationId){
    return axiosInstance.delete(`/organization/${organizationId}`)
}


const OrganizationService = {
    getOrganization,
    getOrganizations,
    updateOrganization,
    createOrganization,
    deleteOrganization,
    getOrganizationJds
}
export default OrganizationService;
import { BounceLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { Timeline } from 'primereact/timeline';
import { MdOutlineClear } from "react-icons/md";
import ResumeService from '../../services/resume.service';
import { capitalizeFirstLetterOfEachWord, parseToISTFromUTC } from '../../utils/common';

export default function ResumeActivitiesPopup(props){
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchTimeline(){
            try {
                setLoading(true);
                const response = await ResumeService.getTimeline(props?.['timelineId']);
                setLoading(false)
                if(response.status === 200){
                    const events = response.data?.['data'] || [];
                    setEvents(events);
                };
            } catch (error) {
                setLoading(false);
            }
        }

        fetchTimeline();
    }, []);
    
    if(events?.length === 0){
        return (
            <div className="h-screen w-full absolute left-0 z-40 top-0 bg-transparent flex justify-center items-center">
                <div className="w-1/3 p-5 flex flex-col gap-8 bg-white shadow-md rounded-md drop-shadow-md">
                    <div className='flex justify-between items-center'>
                        <h1 className='text-primary text-xl font-semibold'>Resume Timeline</h1>
                        <MdOutlineClear 
                            size={20}
                            onClick={props.hide}
                            className="cursor-pointer"
                        />
                    </div>
                    <div className='flex justify-between items-center w-full'>
                        {!loading && <span className='text-primary font-normal'>No activities</span>}
                        {loading && <BounceLoader size={26} color="#2B4360" className='m-auto' />}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="h-screen w-full absolute left-0 z-40 top-0 bg-transparent flex justify-center items-center">
            <div className="w-1/3 max-h-[90%] px-5 pt-5 pb-3 flex flex-col gap-8 bg-white shadow-md rounded-md drop-shadow-md">
                <div className='flex justify-between items-center'>
                    <h1 className='text-primary text-xl font-semibold'>Resume Timeline</h1>
                    <MdOutlineClear 
                        size={20}
                        onClick={props.hide}
                        className="cursor-pointer"
                    />
                </div>
                <div className='flex flex-col overflow-y-scroll'>
                    <Timeline 
                        value={events} 
                        className=''
                        opposite={(item) => (
                            <div className=''>
                                <p className='font-medium text-blue text-sm'>{capitalizeFirstLetterOfEachWord(item?.['type'])} {capitalizeFirstLetterOfEachWord(item?.['name'])}</p>
                            </div>
                        )} 
                        content={(item) => (
                            <small className="text-color-secondary">
                                {parseToISTFromUTC(item.timestamp)}
                            </small>
                        )} 
                    />
                </div>
            </div>
        </div>
    )
}
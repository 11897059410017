import axiosInstance from ".";
import { getDateRange } from "../utils/common";

function getJds(page=1, limit=10, sort, sortField, dateRange){
    let query = `page=${page}&limit=${limit}`;
    if(dateRange) query+= `&dateRange=${getDateRange(dateRange)}`
    
    if(sort && sortField)
        return axiosInstance.get(`/jds?${query}&sort=${sort}&sortField=${sortField}`)
    
    return axiosInstance.get(`/jds?${query}`)
}

function getJd(jdId){
    return axiosInstance.get(`/jds/${jdId}`)
}

function createJD(data){
    return axiosInstance.post('/jds', data)
}

function updateJD(jdId, data){
    return axiosInstance.put(`/jds/${jdId}`, data)
}

function inactivateJD(jdId){
    return axiosInstance.put(`/jds/${jdId}/inactivate`)
}

function activateJD(jdId){
    return axiosInstance.put(`/jds/${jdId}/activate`)
}

function generateJD(data){
    return axiosInstance.post('/generate-jd', data)
}

function sendInterviewInvite(jdId, data){
    return axiosInstance.post(`/jds/${jdId}/sendInvite`, data)
}

const JDService = {
    getJd,
    getJds,
    createJD,
    updateJD,
    generateJD,
    inactivateJD,
    activateJD,
    sendInterviewInvite
}

export default JDService;
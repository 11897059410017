import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { Rating } from '@smastrom/react-rating';
import { BounceLoader } from 'react-spinners';
import ConsoleHelper from '../../utils/consoleHelper';
import { RESUME_STATUS } from '../../utils/constants';
import { useAuth } from '../../contextProviders/AuthContext';
import ReportService from '../../services/report.service';
import { useToast } from '../../contextProviders/ToastContext';
import CircularProgress from '../../components/Report/CircularProgress';
import defaultImage from './../../assets/images/default-interview-image.png';
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';
import TranscriptComponent from '../../components/Report/TranscriptComponent';
import InterviewFeedbackPopup from '../../components/Common/InterviewFeedbackPopup';
import AudioAnalysisReportPopup from '../../components/Report/AudioAnalysisReportPopup';
import { capitalizeFirstLetterOfEachWord, convertUTCToLocal, getRating, secondsToMinutes } from '../../utils/common';

export default function ReportTechnicalScreening({report}){
    const {user} = useAuth();
    const videoRef = useRef(null);
    const {showErrorMessage} = useToast();
    const [loading, setLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showAnomaly, setShowAnomaly] = useState(false);
    const [showTranscript, setShowTranscript] = useState(false);
    const [showAudioAnalyisReport, setShowAudioAnalyisReport] = useState(false);
    const [showInterviewFeedbackPopup, setShowInterviewFeedbackPopup] = useState(false);

    const technicalScreening = (report && report?.technical_screening) ? report?.technical_screening[0] : report ? report : {};
    const audioAnalysis = technicalScreening?.audio_analysis?.filter((item) => item?.speaker === 'SPEAKER_00')[0];
    const primarySkills  = (technicalScreening?.primary_skills && Object.keys(technicalScreening?.primary_skills || {}).length) ? technicalScreening?.primary_skills : {};
    const secondarySkills  = (technicalScreening?.secondary_skills && Object.keys(technicalScreening?.secondary_skills || {}).length) ? technicalScreening?.secondary_skills : {};

    const additionalAnalysis = technicalScreening?.['additional_analysis'] || {};
    const primaryNaSkills = [];
    const optionalNaSkills = [];

    Object.keys(primarySkills || {}).forEach((key) => {
        if(primarySkills[key]?.score <= 0 || !primarySkills[key]?.score){
            primaryNaSkills?.push(key)
            // delete primarySkills[key];
        }
    })

    Object.keys(secondarySkills || {}).forEach((key) => {
        if(secondarySkills[key]?.score <= 0 || !secondarySkills[key]?.score){
            optionalNaSkills?.push(key)
        }
    })


    // if(Object.keys(primarySkills).length){
    //     if(primarySkills['NA']) delete primarySkills['NA']
    //     if(primarySkills['na']) delete primarySkills['na']
    // }
    // if(Object.keys(secondarySkills).length){
    //     if(secondarySkills['NA']) delete secondarySkills['NA'] 
    //     if(secondarySkills['na']) delete secondarySkills['na']
    // }

    const timestampToSeconds = (timestamp) => {
      const match = timestamp.match(/(\d{2}):(\d{2}):(\d{2}),(\d{3})/);
      if (!match) throw new Error('Invalid timestamp format');
  
      const [, hours, minutes, seconds, milliseconds] = match.map(Number);
      return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
    };

    const getDurationInSeconds = () => {
        if(technicalScreening?.['start_time'] && technicalScreening?.['end_time']){
            const startTime = new Date(technicalScreening?.['start_time']);
            const endTime = new Date(technicalScreening?.['end_time']);
            const durationMilliseconds = endTime - startTime;
            return durationMilliseconds / 1000;
        } 
        else if(technicalScreening?.['startTime'] && technicalScreening?.['endTime']){
            const startTime = new Date(technicalScreening?.['startTime']);
            const endTime = new Date(technicalScreening?.['endTime']);
            const durationMilliseconds = endTime - startTime;
            return durationMilliseconds / 1000;
        } 
        return 'NA';
    }

    const extractReport = async () => {
        try {
            setLoading(true);
            const response = await ReportService.getReport(report['id'] || technicalScreening['id']);
            setLoading(false);
            if(response.status === 200){
                const blob = new Blob([response.data], { type: "application/pdf" });
                const link = document.createElement("a");

                const fileName = report['candidate'] ? `${report['candidate']['Name']}-${Date.now()}.pdf` : 
                    report['candidate_name'] ? `${report['candidate_name']}-${Date.now()}.pdf` : `${Date.now()}.pdf`

                link.href = URL.createObjectURL(blob);
                link.download = fileName;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({ summary: 'Failed', detail: 'Failed to extract report'})
        }
    }

    if(!technicalScreening || (typeof technicalScreening == 'object' &&  Object.keys(technicalScreening).length === 0)){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>{
                    report?.status['technicalScreening'] === RESUME_STATUS.COMPLETED ? 
                        'Report is being prepared, will be available shortly.' : 'Report not initiated'
                    } 
                </h1>
            </div>
        )
    }

    const getValue = (value) => {
        if(!value) return;
        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('low'))
            return <span className='font-semibold text-dr'>{value}</span>
        else if(v?.includes('medium'))
            return <span className='font-semibold text-do'>{value}</span>
        else if(v?.includes('high'))
            return <span className='font-semibold text-dg'>{value}</span>
    }

    const getTone = (value) => {
        if(!value) return;

        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('happy') || v?.includes('neutral'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('anger'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const getPace = (value) => {
        if(!value) return;

        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('fast'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('slow'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    const showAudioAnalysisReportPopup = () => setShowAudioAnalyisReport(true);
    const hideAudioAnalysisReportPopup = () => setShowAudioAnalyisReport(false);

    const handleSeek = (timestamp) => {
        try {
            const seconds = timestampToSeconds(timestamp);
            if (videoRef.current) {
                videoRef.current.seekTo(Math.floor(seconds), 'seconds');
                setIsPlaying(true);
            }
        } catch (error) {
          ConsoleHelper.error(`Error seeking video: ${JSON.stringify(error)}`);
        }
    };

    function getAnomalyAndSummary(){
        if(!report?.anomalies)
            return {anomaly: null, summary: null}

        const data = report?.anomalies?.replace('Interview Anomalies Data', '');
        const [anomaly, summary] = data.split('### Summary:');
        return { anomaly, summary}
    }

    return (
        <div className='flex flex-col gap-10'>
            {showAudioAnalyisReport && (
                <AudioAnalysisReportPopup 
                    audioAnalysis={audioAnalysis}
                    close={hideAudioAnalysisReportPopup}
                />
            )}
            {showTranscript && (
                <TranscriptComponent 
                    id={technicalScreening?.id} 
                    hide={() => setShowTranscript(false)}
                />
            )}
            {showInterviewFeedbackPopup && (
                <InterviewFeedbackPopup 
                    sessionId={technicalScreening?.id} 
                    hide={() => setShowInterviewFeedbackPopup(false)}
                />
            )}
            <div className={`h-screen w-screen absolute top-0 left-0 z-50 flex justify-center items-center bg-transparent ${showAnomaly ? 'visible' : 'hidden'}`}>
                <div className="flex justify-center items-center w-[70%] xl:w-1/2 relative">
                    <div className="h-9 w-9 rounded-full bg-white absolute -top-4 shadow-md z-20 -right-2 text-primary flex justify-center items-center cursor-pointer">
                        <MdClear 
                            size={22} 
                            onClick={() => setShowAnomaly(false)} 
                        />
                    </div>
                    <div className={`bg-white rounded-md w-full overflow-hidden relative flex flex-col ${getAnomalyAndSummary()?.anomaly ? 'visible' : 'hidden'}`}>
                        <div className="p-5 py-4 sticky top-0 shadow-md">
                            <h1 className='text-xl font-semibold text-primary'>Anomalies detected</h1>
                        </div>
                        <div className="flex p-5 overflow-y-auto max-h-[500px]"> {/* Add max-h and overflow-y-auto */}
                            <CustomReactMarkdown 
                                data={getAnomalyAndSummary()?.anomaly}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between flex-1 h-full gap-3'>
                    {technicalScreening?.video_path && (
                        <ReactPlayer
                            controls
                            pip={false}
                            ref={videoRef}
                            playing={isPlaying}
                            onPlay={handlePlay}
                            onPause={handlePause}
                            className="react-player flex-1"
                            url={technicalScreening?.video_path}
                        />
                    )}
                    {!technicalScreening?.['video_path'] && (
                        <div  className="react-player flex-1 flex-col-reverse flex gap-4 relative">
                            <img 
                                alt='default'
                                src={defaultImage}
                                className='h-full rounded-md'
                            />
                            <div className='absolute bottom-20 z-50 px-3 text-center flex justify-center items-center w-full'>
                                <p className='text-white font-bold text-center text-base'>Video of the interview is not available</p>
                            </div>
                        </div>
                    )}
                    <div className='flex flex-col gap-10 items-start flex-1 py-5 px-2'>
                        <div className='flex items-center justify-between gap-10'>
                            <div className={`flex flex-col gap-5 items-center ${parseFloat(report?.total_score).toFixed(1) > 0 ? 'visible': 'hidden'}`}>
                                <h2 className='font-semibold text-[1.03rem] text-center'>Mandatory Skills</h2>
                                <CircularProgress
                                    size={70}
                                    strokeWidth={7} 
                                    score={parseFloat(report?.total_score).toFixed(1)}
                                />
                                <div className='flex flex-col gap-4 items-center w-full'>
                                    <div className='flex flex-col gap-1 items-center justify-center'>
                                        <Rating 
                                            readOnly
                                            color='#B99000' 
                                            cancel={false} 
                                            style={{width: 100}}
                                            value={getRating(report?.total_score, user?.['organization']).value} 
                                        />
                                        <span className='font-semibold text-xs'>{getRating(report?.total_score, user?.['organization']).value} out of 5 stars</span>
                                    </div>
                                    {/* <h2 className='text-sm text-primary font-semibold text-center'>{getRating(report?.total_score, user?.['organization']).title}</h2> */}
                                </div>
                                {/* <h2 className='text-sm text-primary font-semibold'>{getRating(report?.total_score, user?.['organization']).title}</h2> */}
                            </div>
                            <div className={`flex flex-col gap-5 items-center ${report?.['id'] !== '6789bbfc3493bada053563ae' && parseInt(technicalScreening?.['coding_score']) > 0 ? 'visible': 'hidden'}`}>
                                <h2 className='font-semibold text-[1.03rem] text-center'>Coding Score</h2>
                                <CircularProgress
                                    size={70}
                                    strokeWidth={7} 
                                    score={parseFloat(technicalScreening['coding_score'] || 0).toFixed(1)}
                                />
                                <div className='flex flex-col gap-4 items-center w-full'>
                                    <div className='flex flex-col gap-1 items-center justify-center'>
                                        <Rating 
                                            readOnly
                                            color='#B99000' 
                                            cancel={false} 
                                            style={{width: 100}}
                                            value={getRating(parseFloat(technicalScreening['coding_score'] || 0).toFixed(1)).value} 
                                        />
                                        <span className='font-semibold text-xs'>{getRating(parseFloat(technicalScreening?.['coding_score'] || 0).toFixed(1)).value} out of 5 stars</span>
                                    </div>
                                    {/* <h2 className='text-sm text-primary font-semibold text-center'>{getRating(parseFloat(technicalScreening?.['coding_score'] || 0).toFixed(1)).title}</h2> */}
                                </div>
                            </div>
                            <div className={`flex flex-col gap-5 items-center ${(report?.['id'] === '6789bbfc3493bada053563ae' || technicalScreening?.['id'] === '6789bbfc3493bada053563ae') ? 'visible': 'hidden'}`}>
                                <h2 className='font-semibold text-[1.03rem] text-center'>Coding Score</h2>
                                <CircularProgress
                                    size={70}
                                    strokeWidth={7} 
                                    score={0}
                                />
                                <div className='flex flex-col gap-4 items-center w-full'>
                                    <div className='flex flex-col gap-1 items-center justify-center'>
                                        <Rating 
                                            readOnly
                                            color='#B99000' 
                                            cancel={false} 
                                            style={{width: 100}}
                                            value={0} 
                                        />
                                        <span className='font-semibold text-xs'>0 out of 5 stars</span>
                                    </div>
                                    {/* <h2 className='text-sm text-primary font-semibold text-center'>{getRating(parseFloat(technicalScreening?.['coding_score'] || 0).toFixed(1)).title}</h2> */}
                                </div>
                            </div>
                            <div className={`flex flex-col gap-5 items-center ${audioAnalysis?.['summary'] ? 'visible': 'hidden'}`}>
                                <h2 className='font-semibold text-[1.03rem] text-center'>Communication</h2>
                                <CircularProgress
                                    size={70}
                                    strokeWidth={7} 
                                    score={parseFloat(audioAnalysis?.score).toFixed(1)}
                                />
                                <div className='flex flex-col gap-4 items-center w-full'>
                                    <div className='flex flex-col gap-1 items-center justify-center'>
                                        <Rating 
                                            readOnly
                                            color='#B99000' 
                                            cancel={false} 
                                            style={{width: 100}}
                                            value={getRating(parseFloat(audioAnalysis?.score || 0).toFixed(1)).value} 
                                        />
                                        <span className='font-semibold text-xs'>{getRating(audioAnalysis?.score, user?.['organization']).value} out of 5 stars</span>
                                    </div>
                                    {/* <h2 className='text-sm text-primary font-semibold text-center'>{getRating(audioAnalysis?.score, user?.['organization']).title}</h2> */}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-3'>
                            <div className={`flex items-center gap-2 text-primary ${technicalScreening?.start_time ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold w-40'>Date of the interview :</h3>
                                <span className=''>{technicalScreening?.start_time ? convertUTCToLocal(technicalScreening?.start_time) : 'Not captured' }</span>
                            </div>
                            <div className='flex items-center gap-2 text-primary'>
                                <h3 className='font-semibold w-40'>Interview Duration:</h3>
                                <span className=''>{secondsToMinutes(getDurationInSeconds())}</span>
                            </div>
                        </div>
                        {/* <button
                            disabled={loading}
                            onClick={extractReport} 
                            className='border h-9 w-36 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary'>
                            {loading ? <BounceLoader size={26} color="#2B4360" /> : <span className="text-xs">Extract Report</span>}
                        </button> */}
                    </div>
                </div>
                <div className='flex items-center gap-5'>
                    <button
                        onClick={() => setShowTranscript(!showTranscript)}
                        className='border text-xs h-9 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary'>
                        View Transcript
                    </button>
                    <button
                        disabled={loading}
                        onClick={extractReport} 
                        className={`border h-9 w-36 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary ${report?.total_score > 0 ? 'visible': 'hidden'}`}>
                        {loading ? <BounceLoader size={26} color="#2B4360" /> : <span className="text-xs">Extract Report</span>}
                    </button>
                    <button
                        onClick={() => setShowInterviewFeedbackPopup(true)} 
                        className={`border h-9 px-6 text-xs flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary ${!technicalScreening?.['report_feedback']?.status ? 'visible': 'hidden'}`}>
                        Next round status
                    </button>
                </div>
                {typeof technicalScreening?.summary === "object" ? technicalScreening?.summary?.summary : technicalScreening?.summary && (
                    <div>
                        <h4 className='text-xl font-bold'>Summary</h4>
                        <CustomReactMarkdown
                            data={typeof technicalScreening?.summary === "object" ? technicalScreening?.summary?.summary : technicalScreening?.summary}
                        />
                    </div>
                )}
            </div>
            <div className={`${report?.anomalies_status ? 'visible': 'hidden'} flex flex-col gap-2 text-primary`}>
                <div className='flex items-center gap-10'>
                    <h2 className='text-xl font-bold'>Anomalies</h2>
                    <button 
                        onClick={() => setShowAnomaly(true)} 
                        className={`text-sm cursor-pointer font-semibold text-blue ${getAnomalyAndSummary()?.anomaly  && !report['anomalies']?.toLowerCase().includes('not found') ? 'visible': 'hidden'}`}>
                        View more
                    </button>
                </div>
                <p className={`${report?.anomalies_status?.status === 'red'? 'text-dr': report?.anomalies_status?.status === 'green'? 'text-dg': 'text-do'} font-medium`}>
                    {report?.anomalies_status?.description}
                </p>
                <div className={`${getAnomalyAndSummary()?.summary != null ? 'visible': 'hidden'} flex items-start gap-2 text-primary`}>
                    <h3 className='font-semibold'>Summary:</h3>
                    <CustomReactMarkdown data={getAnomalyAndSummary()?.summary}/>
                </div>
            </div>
            <div className={`flex gap-4 flex-col ${(Object.keys(primarySkills).length || primaryNaSkills?.length > 0) ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Mandatory Skills</h2>
                <ul className='flex gap-10 flex-wrap'>
                    {Object.keys(primarySkills || {}).map((item, index) => {
                        const object = primarySkills[item];
                        const score = parseInt(object.score);
                        return (
                            <li 
                                className={`w-[30%] ${score <= 0 ? 'hidden': 'visible'}`}
                                key={index?.toString()}>
                                <div className='flex justify-between items-center'>
                                    <h5 className='font-bold'>{capitalizeFirstLetterOfEachWord(item)}</h5>
                                    <div className={`flex flex-col  justify-center items-center ${score <= 0 ? 'visible': 'hidden'}`}>
                                        <span className='font-semibold text-xs'>NA</span>
                                    </div>
                                    <div className={`flex gap-4 justify-center items-center ${score > 0 ? 'visible': 'hidden'}`}>
                                        <CircularProgress
                                            size={40}
                                            strokeWidth={4} 
                                            score={parseFloat(score).toFixed(1)}
                                        />
                                        <div className='flex flex-col gap-1 items-center'>
                                            <Rating 
                                                readOnly
                                                color='#B99000' 
                                                cancel={false} 
                                                style={{width: 100}}
                                                value={score/20} 
                                            />
                                            <span className='font-semibold text-xs'>
                                                {score / 20} out of 5 stars
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2 mt-2'>
                                    <h5 className='font-semibold text-sm'>Reason:</h5>
                                    <CustomReactMarkdown className='pb-3' data={object?.['reason']}/>
                                    <div className={`flex flex-col gap-3 ${technicalScreening["video_snippets"]?.[item]?.length > 0 ? 'visible': 'hidden'}`}>
                                        <h3 className="font-semibold text-sm">Video Coverage</h3>
                                        <ul className={'flex flex-wrap gap-5'}>
                                            {technicalScreening["video_snippets"]?.[item]?.map((item) => (
                                                <li key={item} className='font-semibold text-xs flex gap-5 text-blue'>
                                                    <span className="cursor-pointer" onClick={handleSeek.bind(this, item[0])}>{item[0]}</span>
                                                    <span  className="cursor-pointer" onClick={handleSeek.bind(this, item[1])}>{item[1]}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
                <p className={`font-normal text-sm ${primaryNaSkills?.length > 0 ? 'visible': 'hidden'}`}>
                    No information found for <span className='font-semibold'>{primaryNaSkills?.map((item) => capitalizeFirstLetterOfEachWord(item)).join(", ")}</span> skills
                </p>
            </div>
            <div className={`flex flex-col gap-4 ${(Object.keys(secondarySkills).length || optionalNaSkills?.length > 0) ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Optional Skills</h2>
                <ul className='flex gap-10 flex-wrap'>
                    {Object.keys(secondarySkills || {}).map((item, index) => {
                        const object = secondarySkills[item];
                        const score = parseInt(object.score);
                        return (
                            <li
                                className={`w-[30%] ${score <= 0 ? 'hidden': 'visible'}`}
                                key={index?.toString()}>
                                <div className='flex justify-between items-center'>
                                    <h5 className='font-bold'>{capitalizeFirstLetterOfEachWord(item)}</h5>
                                    <div className={`flex flex-col  justify-center items-center ${score <= 0 ? 'visible': 'hidden'}`}>
                                        <span className='font-semibold text-xs'>NA</span>
                                    </div>
                                    <div className={`flex gap-4 justify-center items-center ${score > 0 ? 'visible': 'hidden'}`}>
                                        <CircularProgress
                                            size={40}
                                            strokeWidth={4} 
                                            score={parseFloat(score).toFixed(1)}
                                        />
                                        <div className='flex flex-col gap-1 items-center'>
                                            <Rating 
                                                readOnly
                                                color='#B99000' 
                                                cancel={false} 
                                                style={{width: 100}}
                                                value={score/20} 
                                            />
                                            <span className='font-semibold text-xs'>
                                                {score / 20} out of 5 stars
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2 mt-2'>
                                    <h5 className='font-semibold'>Reason:</h5>
                                    <CustomReactMarkdown className='pb-3' data={object?.['reason']}/>
                                    <div className={`flex flex-col gap-3 ${technicalScreening["video_snippets"]?.[item]?.length > 0 ? 'visible': 'hidden'}`}>
                                        <h3 className="font-semibold text-sm">Video Coverage</h3>
                                        <ul className={'flex flex-wrap gap-5'}>
                                            {technicalScreening["video_snippets"]?.[item]?.map((item) => (
                                                <li key={item} className='font-semibold text-xs flex gap-5 text-blue'>
                                                    <span className="cursor-pointer" onClick={handleSeek.bind(this, item[0])}>{item[0]}</span>
                                                    <span  className="cursor-pointer" onClick={handleSeek.bind(this, item[1])}>{item[1]}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
                <p className={`font-normal text-sm ${optionalNaSkills?.length > 0 ? 'visible': 'hidden'}`}>
                    No information found for <span className='font-semibold'>{optionalNaSkills?.map((item) => capitalizeFirstLetterOfEachWord(item))?.join(", ")}</span> skills
                </p>
            </div>
            <div className={`${(report?.['id'] !== '6789bbfc3493bada053563ae' || technicalScreening?.['id'] !== '6789bbfc3493bada053563ae') && parseInt(technicalScreening?.['coding_score']) > 0 ? 'visible flex flex-col gap-5': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Coding Analysis</h2>
                <div className='flex flex-col gap-4 text-primary'>
                    <CustomReactMarkdown data={technicalScreening?.['coding_evaluation_summary']}/>
                </div>
            </div>
            <div className={`${(report?.['id'] === '6789bbfc3493bada053563ae' || technicalScreening?.['id'] === '6789bbfc3493bada053563ae')  ? 'visible flex flex-col gap-3': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Coding Analysis</h2>
                <p>Candidate chose not to answer the coding question</p>
            </div>
            <div className={`flex flex-col ${audioAnalysis?.['summary'] ? 'visible': 'hidden'} gap-4`}>
                <div className='flex gap-4'>
                    <h2 className='text-xl font-bold'>Communication Skills</h2>
                    <button 
                        onClick={showAudioAnalysisReportPopup} 
                        className='font-medium text-blue'>
                        Show more
                    </button>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className="flex flex-wrap gap-5">
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.tone ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Tone : </h2>
                            {getTone(audioAnalysis?.tone)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.pace || 'Medium' ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Pace : </h2>
                            {getPace(audioAnalysis?.pace || 'Medium')}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.speech_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Speech Quality : </h2>
                            {getValue(audioAnalysis?.speech_quality)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_volume ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Volume : </h2>
                            {getValue(audioAnalysis?.audio_volume)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Quality : </h2>
                            {getValue(audioAnalysis?.audio_quality)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${Object.keys(additionalAnalysis)?.length > 0 ? 'visible flex flex-col gap-5': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Additional analysis based on resume</h2>
                <div className='flex flex-col gap-4 text-primary'>
                    <CustomReactMarkdown data={additionalAnalysis?.['summary']}/>
                    {Object.keys(additionalAnalysis)?.map((item) => {
                        if (item !== 'summary') {
                            return (
                                <div className='flex flex-col gap-2' key={item}>
                                    <div className='flex gap-5 items-center'>
                                        <h3 className='text-sm font-bold'>{capitalizeFirstLetterOfEachWord(item?.replaceAll("_", " "))}</h3>
                                        <CircularProgress
                                            size={36}
                                            strokeWidth={4} 
                                            score={parseFloat(additionalAnalysis?.[item]?.score).toFixed(1)}
                                        />
                                    </div>
                                    <CustomReactMarkdown data={additionalAnalysis?.[item]?.evidence}/>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
            <div className={`flex flex-col gap-3 ${technicalScreening?.['report_feedback']?.status ? 'visible': 'hidden'}`}>
                <div className='flex gap-4 items-center'>
                    <h1 className='text-xl font-bold'>Recruiter feedback on next round</h1>
                    <div className={`text-sm h-7 px-3 rounded-full flex justify-center items-center font-semibold ${technicalScreening['report_feedback']?.status === 'SELECTED' ? 'bg-dg': technicalScreening['report_feedback']?.status === 'REJECTED' ? 'bg-dr': 'bg-do'}`}>
                        <span className={`text-xs ${technicalScreening['report_feedback']?.status === 'SELECTED' ? 'text-lgg': technicalScreening['report_feedback']?.status === 'REJECTED' ? 'text-lr': 'text-lo'}`}>{technicalScreening['report_feedback']?.status}</span>
                    </div>
                </div>
                {technicalScreening['report_feedback']?.feedback && <p className='text-primary'>{technicalScreening['report_feedback']?.feedback}</p>}
            </div>
            <div className={`flex flex-col gap-3 ${technicalScreening['candidate_feedback'] && technicalScreening['candidate_feedback']?.rating >= 1 ? 'visible': 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Candidate Feedback</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={technicalScreening['candidate_feedback']?.rating} 
                    />
                </div>
                <p className='text-primary'>{technicalScreening['candidate_feedback']?.message}</p>
            </div>
        </div>
    )
}
import { MdClear } from "react-icons/md";
import MDEditor from '@uiw/react-md-editor';

export default function JDQuestionsPreviewPopup({close, questions}){
 
    return (
        <div className="h-screen w-screen z-50 absolute top-0 left-0 flex justify-center items-center bg-transparent">
            <div className="flex justify-center items-center h-[95%] relative w-[90%] xl:w-1/2">
                <div className="h-9 w-9 rounded-full bg-white absolute top-3 shadow-md z-20 -right-4 text-primary flex justify-center items-center cursor-pointer">
                    <MdClear size={22} onClick={close} />
                </div>
                <div className="bg-white rounded-md w-full h-[90%] overflow-hidden relative flex flex-col">
                    <div className="px-5 py-4 sticky top-0 shadow-md">
                        <h1 className="text-xl font-medium">Questions</h1>
                    </div>
                    <p className={`${questions?.length === 0 ? 'visible': 'hidden'} text-center py-4 text-base font-medium`}>
                        No additional customized questions
                    </p>
                    <div className={`${questions?.length ? 'visible': 'hidden'} flex overflow-y-scroll h-full px-5`}>
                        <ul className="flex flex-col">
                            {questions?.map((item, index) => (
                                <div 
                                    key={index?.toString()}
                                    className="flex gap-5 py-4 flex-col">
                                    <div 
                                        className={`flex flex-col gap-1 ${item?.question ? 'visible': 'hidden'}`}>
                                        <h1 className="font-semibold text-xs text-primary">Question {index + 1}</h1>
                                        {/* <p className="text-darkGray font-normal">{item.question}</p> */}
                                        <MDEditor.Markdown 
                                            source={item.question}
                                            className="text-darkGray font-normal" 
                                        />
                                    </div>
                                    <div 
                                        className={`flex flex-col gap-1 ${item?.answer ? 'visible': 'hidden'}`}>
                                        <h1 className="font-semibold text-xs text-primary">Answer {index + 1}</h1>
                                        <p className="text-darkGray font-normal text-sm">{item.answer}</p>
                                    </div>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

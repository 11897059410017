import { useState } from "react";
import PropTypes from 'prop-types';
import { Chip } from "primereact/chip";
import { Slider } from "primereact/slider";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { convertToRange } from "../../../utils/common";
import { EMPLOYMENT_TYPES } from '../../../utils/constants';
import { useJDInfo } from "../../../contextProviders/JDInfoContext";

function JDGenerateEditSection(){
    const { getJDInfo, changeJDInfo} = useJDInfo();

    const jdInfo = getJDInfo();
    const [error, setError] = useState({});
    
    const [title, setTitle] = useState(jdInfo?.title || null);
    const [location, setLocation] = useState(jdInfo?.location || null);

    const initialExperience = jdInfo?.['experience'] ? convertToRange( jdInfo?.['experience']) : [2,5];
    const [experience, setExperience] = useState(initialExperience);
    
    const initialEmploymentType = 
        typeof jdInfo?.['employmentType'] === "string" ? { name: jdInfo?.['employmentType'], code: jdInfo?.['employmentType'] } :  
        typeof jdInfo?.['employmentType'] === "object" ? jdInfo?.['employmentType']  : {};

    const [employmentType, setEmploymentType] = useState(initialEmploymentType);

    const getSkills = (skills) => {
        if(!skills || skills?.length === 0) return [];

        if(typeof skills[0] === 'object')
            return skills?.map((item) => item?.name); 
        return skills;
    }

    const [primarySkillText, setPrimarySkillsText] = useState(jdInfo?.primarySkills ? getSkills(jdInfo?.primarySkills)?.join(",") : null);
    const [secondarySkillsText, setSecondarySkillsText] = useState(jdInfo?.secondarySkills ? getSkills(jdInfo?.secondarySkills)?.join(",") : null);

    const handleChanges = (key, value) => changeJDInfo({[key]: value});

    return (
        <div className="flex flex-col h-full gap-5">
            <div className="bg-white p-5 rounded-md">
                <div className="bg-white rounded gap-10 flex flex-col mt-2">
                    <div className="flex gap-5">
                        <div className="flex-col flex flex-1 gap-2">
                            <label className="text-darkGray font-medium text-sm" htmlFor="title">Role / Job Title</label>
                            <InputText 
                                id="title"
                                value={title}
                                placeholder="Enter title" 
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                    handleChanges('title', e.target.value) 
                                }}
                                className="border-l_border border-[1px] h-10 p-2"
                            />
                            {error['title'] && <span className="text-dr font-normal text-xs">{error['title']}</span>}
                        </div>
                        <div className="flex-1 flex flex-col gap-2">
                            <label  
                                htmlFor="experience"
                                className="text-darkGray font-medium text-sm">
                                Experience
                            </label>
                            <div className="flex flex-col gap-4 w-1/2">
                                <div className="flex justify-between">
                                    <div className="flex gap-2 items-center">
                                        <InputText
                                            min={0}
                                            max={50} 
                                            value={experience[0]}
                                            id="minExperience"
                                            contentEditable={false}
                                            className="border-l_border rounded-md text-sm border-[1px] text-center h-10 w-10"
                                        />
                                        <span>years</span>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <InputText
                                            min={0}
                                            max={50} 
                                            value={experience[1]}
                                            id="maxExperience"
                                            contentEditable={false}
                                            className="border-l_border rounded-md text-sm border-[1px] text-center h-10 w-10"
                                        />
                                        <span>years</span>
                                    </div>
                                </div>
                                <Slider
                                    range
                                    min={0} 
                                    max={30}
                                    value={experience} 
                                    className="w-14rem"
                                    onChange={(e) => {
                                        setExperience(e.value)
                                        handleChanges('experience', e.value) 
                                    } 
                                } />
                            </div>
                            {error['experience'] && <span className="text-dr font-normal text-xs">{error['experience']}</span>}
                        </div>
                    </div>
                    <div className="flex gap-5">
                        <div className="flex-col flex flex-1 gap-2">
                            <label className="text-darkGray font-medium text-sm" htmlFor="location">Location</label>
                            <InputText 
                                id="location"
                                value={location}
                                placeholder="Add comma to add multiple locations" 
                                onChange={(e) => {
                                    setLocation(e.target.value);
                                    handleChanges('location', e.target.value) 
                                }}
                                className="border-l_border border-[1px] h-10 p-2"
                            />
                            {error['location'] && <span className="text-dr font-normal text-xs">{error['location']}</span>}
                        </div>
                        <div className="flex-1 flex flex-col gap-2">
                            <label className="text-darkGray font-medium text-sm" htmlFor="employmentType">Employment Type</label>
                            <Dropdown
                                checkmark={true} 
                                optionLabel="name"  
                                value={employmentType}
                                highlightOnSelect={false}
                                options={EMPLOYMENT_TYPES}  
                                defaultValue={employmentType} 
                                onChange={(e) => {
                                    setEmploymentType(e.value)
                                    handleChanges('employmentType', e.value.code) 
                                }} 
                                placeholder="Select employment type" 
                                className="h-10 border-l_border border-[1px] " 
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {error['employmentType'] && <span className="text-dr font-normal text-xs">{error['employmentType']}</span>}
                        </div>
                    </div>
                    <div className="flex gap-5 flex-col">
                        <div className="flex-col flex flex-1 gap-2">
                            <div className='flex gap-5 items-center'>
                                <label className="text-darkGray font-medium text-sm" htmlFor="primary-skills">Mandatory Skills</label>
                                {primarySkillText?.split(',').filter(word => word.trim())?.length > 4 && <span className='text-do text-sm'>Note: Interview will be conducted for first 4 skills </span>}
                            </div>
                            <InputText 
                                id="primary-skills"
                                value={primarySkillText}
                                placeholder="Add comma to seperate skills" 
                                className="border-l_border border-[1px] h-10 p-2"
                                onChange={(event) => {
                                    setPrimarySkillsText(event.target.value)
                                    if(event.target.value && event.target.value.trim().length > 0)
                                        handleChanges('primarySkills', event.target.value?.split(',')?.filter(word => word.trim()));
                                    else handleChanges('primarySkills', []) 
                                }}
                            />
                            {error['primarySkills'] && <span className="text-dr font-normal text-xs">{error['primarySkills']}</span>}
                            {primarySkillText?.split(',').filter(word => word.trim())?.length > 0 && (
                                <div className="flex flex-wrap gap-2 mt-2">
                                {primarySkillText?.split(',')?.filter(word => word.trim()).map((item, index) => <Chip id={index} key={index} label={item}/>)}
                                </div>
                            )}
                        </div>
                        <div className="flex-1 flex flex-col gap-2">
                            <label className="text-darkGray font-medium text-sm" htmlFor="Add comma to seperate skills">Optional Skills</label>
                            <InputText 
                                id="secondary-skills"
                                value={secondarySkillsText}
                                placeholder="Enter Add comma to seperate skills"
                                className="border-l_border border-[1px] h-10 p-2"
                                onChange={(event) => {
                                    setSecondarySkillsText(event.target.value)
                                    if(event.target.value && event.target.value.trim().length > 0)
                                        handleChanges('secondarySkills', event.target.value?.split(',')?.filter(word => word.trim())) 
                                    else handleChanges('secondarySkills', []) 
                                }}
                            />
                            {error['secondarySkills'] && <span className="text-dr font-normal text-xs">{error['secondarySkills']}</span>}
                            {secondarySkillsText?.split(',')?.filter(word => word.trim())?.length > 0 && (
                                <div className="flex flex-wrap gap-2 mt-2">
                                {secondarySkillsText?.split(',')?.filter(word => word.trim())?.map((item, index) => (
                                    <Chip id={index} key={index} label={item}/>
                                ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

JDGenerateEditSection.propTypes = {
    setActiveTabIndex: PropTypes.func
}

export default JDGenerateEditSection;
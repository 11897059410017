import React, { useEffect, useMemo, useState } from "react";
import Editor from '@monaco-editor/react';
import MDEditor from '@uiw/react-md-editor';
import { Dropdown } from "primereact/dropdown";
import VideoPreview from "../Common/VideoPreview";
import { useToast } from "../../contextProviders/ToastContext";
import { useSessionContext } from "../../contextProviders/SessionContext";
import { useCode } from "../../contextProviders/CodeContext";

const languageOptions = [
    { label: "Plain Text", value: "plaintext" },
    { label: "ABAP", value: "abap" },
    { label: "Apex", value: "apex" },
    { label: "Azure CLI", value: "azcli" },
    { label: "Batch", value: "bat" },
    { label: "Bicep", value: "bicep" },
    { label: "CameLIGO", value: "cameligo" },
    { label: "Clojure", value: "clojure" },
    { label: "CoffeeScript", value: "coffeescript" },
    { label: "C", value: "c" },
    { label: "C++", value: "cpp" },
    { label: "C#", value: "csharp" },
    { label: "CSP", value: "csp" },
    { label: "CSS", value: "css" },
    { label: "Cypher", value: "cypher" },
    { label: "Dart", value: "dart" },
    { label: "Dockerfile", value: "dockerfile" },
    { label: "ECL", value: "ecl" },
    { label: "Elixir", value: "elixir" },
    { label: "Flow9", value: "flow9" },
    { label: "F#", value: "fsharp" },
    { label: "FreeMarker2", value: "freemarker2" },
    { label: "Go", value: "go" },
    { label: "GraphQL", value: "graphql" },
    { label: "Handlebars", value: "handlebars" },
    { label: "HCL", value: "hcl" },
    { label: "HTML", value: "html" },
    { label: "INI", value: "ini" },
    { label: "Java", value: "java" },
    { label: "JavaScript", value: "javascript" },
    { label: "Julia", value: "julia" },
    { label: "Kotlin", value: "kotlin" },
    { label: "LESS", value: "less" },
    { label: "Lexon", value: "lexon" },
    { label: "Lua", value: "lua" },
    { label: "Liquid", value: "liquid" },
    { label: "M3", value: "m3" },
    { label: "Markdown", value: "markdown" },
    { label: "MDX", value: "mdx" },
    { label: "MIPS", value: "mips" },
    { label: "MSDAX", value: "msdax" },
    { label: "MySQL", value: "mysql" },
    { label: "Objective-C", value: "objective-c" },
    { label: "Pascal", value: "pascal" },
    { label: "PascaLIGO", value: "pascaligo" },
    { label: "Perl", value: "perl" },
    { label: "PostgreSQL", value: "pgsql" },
    { label: "PHP", value: "php" },
    { label: "PLA", value: "pla" },
    { label: "PowerQuery", value: "powerquery" },
    { label: "PowerShell", value: "powershell" },
    { label: "Protocol Buffers", value: "proto" },
    { label: "Pug", value: "pug" },
    { label: "Python", value: "python" },
    { label: "Q#", value: "qsharp" },
    { label: "R", value: "r" },
    { label: "Razor", value: "razor" },
    { label: "Redis", value: "redis" },
    { label: "Redshift", value: "redshift" },
    { label: "reStructuredText", value: "restructuredtext" },
    { label: "Ruby", value: "ruby" },
    { label: "Rust", value: "rust" },
    { label: "SB", value: "sb" },
    { label: "Scala", value: "scala" },
    { label: "Scheme", value: "scheme" },
    { label: "SCSS", value: "scss" },
    { label: "Shell", value: "shell" },
    { label: "Solidity", value: "sol" },
    { label: "AES", value: "aes" },
    { label: "SPARQL", value: "sparql" },
    { label: "SQL", value: "sql" },
    { label: "ST", value: "st" },
    { label: "Swift", value: "swift" },
    { label: "SystemVerilog", value: "systemverilog" },
    { label: "Verilog", value: "verilog" },
    { label: "Tcl", value: "tcl" },
    { label: "Twig", value: "twig" },
    { label: "TypeScript", value: "typescript" },
    { label: "TypeSpec", value: "typespec" },
    { label: "Visual Basic", value: "vb" },
    { label: "WGSL", value: "wgsl" },
    { label: "XML", value: "xml" },
    { label: "JSON", value: "json" },
    { label: "YAML", value: "yaml" }
];

function CodeEditor(props){
    const { code, setCode } = useCode();
    const {transcripts} = useSessionContext();
    const {showWarningMessage} = useToast();
    const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds
    const [language, setLanguage] = useState("javascript");
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                if(timeLeft <= 1){
                    showWarningMessage({
                        life: 30 * 1000,
                        summary: 'Time is Running Out', 
                        detail: 'Please complete or submit your code now. Your code will auto-submit in 2-3 minutes.'
                    })
                }
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
            return () => clearInterval(timer); // Cleanup on component unmount
        }
    }, [timeLeft]);

    const handleEditorChange = (value) => {
        setCode(value);
    };

    const accept = () => {
        props.submitCode("Skip the question")
    }

    const reject = () => {
        setShowConfirmDialog(false);
    }

    const showConfirmPopup = () => {
        setShowConfirmDialog(true);        
    };

    const formattedTime = useMemo(() => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }, [timeLeft]);

    return (
        <div className="h-screen font-normal w-screen bg-transparent absolute top-0 left-0 flex justify-center items-center gap-10 z-50 px-10 py-5">
            {showConfirmDialog && (
                <div className="h-full w-full absolute top-0 bg-transparent z-10 flex justify-center items-center">
                    <div className="bg-white shadow-sm drop-shadow-md p-5 w-1/3 rounded-md gap-7 flex flex-col">
                        <div className="flex flex-col gap-3">
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl text-brownGray font-semibold">Confirmation</h2>
                            </div>
                            <p className="font-normal">Do you want to skip the coding question and continue with non-coding questions?</p>
                        </div>
                        <div className="flex gap-10 items-center">
                            <button
                                onClick={accept} 
                                className="bg-blue text-white h-9 px-10 rounded-md">
                                Yes
                            </button>
                            <button
                                onClick={reject}  
                                className="bg-blue text-white h-9 px-10 rounded-md">
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex-1 w-2/3 gap-2 bg-white h-full rounded-md flex flex-col justify-between">
                <div className="flex justify-between gap-2 p-3 items-center">
                    <Dropdown
                        editable 
                        value={language} 
                        optionLabel="label"
                        options={languageOptions}  
                        onChange={(e) => setLanguage(e.value)}  
                        pt={{
                            input: {className: 'text-sm'},
                            itemLabel: {className: 'text-sm'},
                            item: {className: 'p-3'}
                        }} 
                        defaultValue={language}
                        highlightOnSelect={false}
                        placeholder="Select language" 
                        className="h-10 border-l_border border-[1px] pl-3"
                    />
                    <div className="flex justify-center items-center flex-1 gap-2">
                        <label className="text-darkGray text-sm font-medium">Remaining Time </label>
                        <div className="bg-blue px-4 h-9 flex text-white text-sm justify-center items-center rounded-md">
                            <span className="font-medium">{formattedTime} minutes</span>
                        </div>
                    </div>
                    <div className="flex gap-2 ml-auto">
                        <button 
                            onClick={showConfirmPopup} 
                            className="text-primary font-semibold bg-white border-primary h-10 px-5 rounded-md text-sm">
                            Cancel
                        </button>
                        <button 
                            onClick={() => {props.submitCode(code)}} 
                            className="bg-primary font-semibold text-white h-10 px-5 rounded-md text-sm">
                            Submit Code
                        </button>
                    </div>
                </div>
                <div className="flex flex-col h-screen overflow-y-scroll">
                    <div className="px-5 flex flex-col gap-2 mb-3">
                        <h1>Question</h1>
                        <MDEditor.Markdown 
                            source={transcripts[transcripts?.length - 1]?.message}
                            className="text-darkGray font-normal" 
                        />
                    </div>
                    <Editor 
                        value={code}
                        theme="vs-dark"
                        language={language}
                        className="h-screen mx-5"
                        defaultLanguage={language} 
                        onChange={handleEditorChange}

                    />
                </div>
            </div>
            <div className="flex w-1/3 h-full rounded-md">
                <VideoPreview isRecording={props.isRecording}/>
            </div>
        </div>
    )
}

export default React.memo(CodeEditor)

import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

export default function FeedbackStatusDropdown(props){
    const menu = useRef(null);
    const [menus, setMenus] = useState([]);
    
    useEffect(() => {
      setMenus([
        {
          label: "SELECTED",
          command: () => {
            props.setInterviewStatus('SELECTED');
            setMenuVisibility(false);
          }
        },
        {
          label: "ON HOLD",
          command: () => {
            props.setInterviewStatus('ON HOLD');
            setMenuVisibility(false);
          }
        },
        {
          label: "REJECTED",
          command: () => {
            props.setInterviewStatus('REJECTED');
            setMenuVisibility(false);
          }
        },
    ])
    }, []);

    const [menuVisibility, setMenuVisibility] = useState(false);

    const onClick = (event) => {                       
        if(menuVisibility) menu.current.hide(event)
        else menu.current.show(event)
        setMenuVisibility(!menuVisibility)
    }

    return (
        <div 
            onClick={onClick} 
            className="bg-white flex px-3 py-2 gap-5 justify-between items-center rounded cursor-pointer relative border-[1px] border-[#EAECF0]">
            <h4 className="text-sm">{props?.['interviewStatus'] || 'Select Status'}</h4>
            {!menuVisibility && <MdOutlineKeyboardArrowDown size={22} /> }
            {menuVisibility && <MdOutlineKeyboardArrowUp size={22} /> }
            <Menu 
                popup 
                ref={menu}
                className="w-32 text-sm"
                model={menus}
            />
        </div>
    )
}